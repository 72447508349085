import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import classnames from "classnames";
import IconError from "../../../images/ic_error.png";

const ValidationMessage = ({
  touched,
  error,
  minLength,
  maxLength,
  fixedLength,
  className,
  label,
  match,
  maxSize,
  firstNumber,
  requiredMobi
}) => {
  return touched && error ? (
    <div className="mt-2" style={{ display: "flex", alignItems: "center" }}>
      <img src={IconError} alt="Errors" style={{ width: 18, height: 18 }} />
      <span className={classnames("form-error pl-2", className)}>
        {error.startsWith("_") ? (
          <Translate
            id={error}
            data={{
              label,
              minLength,
              maxLength,
              match,
              maxSize,
              fixedLength,
              firstNumber,
              requiredMobi
            }}
          />
        ) : (
          error
        )}
      </span>
    </div>
  ) : null;
};

ValidationMessage.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  fixedLength: PropTypes.number,
  label: PropTypes.string,
  match: PropTypes.string,
  maxSize: PropTypes.number
};

export default ValidationMessage;
