import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  Translate,
  getTranslate,
  getActiveLanguage
} from "react-localize-redux";
import { Link } from "react-router-dom";

import {
  createValidator,
  email,
  match,
  required,
  maxLengthEmail,
  password,
  firstNumber,
  minLength,
  maxLength,
  isValidDate
} from "../../helpers/validator";
import CheckField from "../components/fields/CheckField";
import TextField from "../components/fields/TextField";
import DatePickerField from "../components/fields/DatePickerField";
import Button from "../components/forms/Button";
import OtpInput from "react-otp-input";
import { getSysCodeOptions } from "../../redux/modules/SystemCode";
import { bindActionCreators } from "redux";
import SelectField from "../components/fields/SelectField";
import salutationOptions from "../../resources/general/SalutationOptions";
import { uniqueEmail, uniquePhone } from "../../redux/modules/UniqueField";
import { sendOtp } from "../../redux/modules/Otp";
import { load } from "recaptcha-v3";
import { recaptchaKey } from "../../configs/Settings";
import ReCAPTCHA from "react-google-recaptcha";

const validate = createValidator({
  email: [required, email, maxLengthEmail(318)],
  firstName: [required],
  lastName: [required],
  confirmPassword: [required, match("password")],
  password: [required, minLength(8), password],
  mobileNo: [required],
  mobile: [required, firstNumber, maxLength(8)],
  dob: [required, isValidDate],
  gender: [required]
});

export const formId = "sign-up";

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "password",
      typecpw: "password",
      otp: "",
      showOTP: false,
      isDisabled: true
    };
    this.showHide = this.showHide.bind(this);
    this.showHideCpw = this.showHideCpw.bind(this);
    this.recaptchaRef = React.createRef();
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "input" ? "password" : "input"
    });
  }
  showHideCpw(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      typecpw: this.state.typecpw === "input" ? "password" : "input"
    });
  }
  showOTP = val => {
    this.setState({ showOTP: val });
  };

  validateEmail = value => {
    console.log("validate email");
    return value &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
      ? "Invalid email address"
      : undefined;
  };

  checkEmail = () => {
    let email = this.form.email.value;
    if (email) {
      this.props.dispatch(uniqueEmail(email));
    }
  };

  handleSendOtp = () => {
    const mobile = this.props.mobile;
    const countryCode = this.props.countryCode;
    const phoneNumber = countryCode + mobile;
    console.log("handleSendOtp");
    this.setState({ isDisabled: true }, () => {
      if (
        mobile.length == 8 &&
        (mobile.charAt(0) == 8 || mobile.charAt(0) == 9)
      ) {
        return this.props.dispatch(
          uniquePhone(phoneNumber, async () => {
            var token = await this.recaptchaRef.current.execute();
            console.log("Captcha Token", token);
            this.props.dispatch(sendOtp(phoneNumber, token));
          })
        );
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    let mobile = nextProps.mobile ? nextProps.mobile : "";
    if (
      mobile.length == 8 &&
      (mobile.charAt(0) == "8" || mobile.charAt(0) == "9")
    ) {
      this.setState({
        isDisabled: false,
        // showOTP: false,
        otp: nextProps.activeBtnOTP ? "" : this.state.otp
      });
    } else {
      this.setState({
        isDisabled: true,
        // showOTP: false,
        otp: nextProps.activeBtnOTP ? "" : this.state.otp
      });
    }
    if (nextProps.activeBtnOTP) {
      this.setState({ isDisabled: false, showOTP: true });
    }
  }

  render() {
    let {
      error,
      handleSubmit,
      pristine,
      translate,
      submitting,
      currentLanguage
    } = this.props;

    let gender = this.props.initialValues.gender;
    let genderCodes = [];
    if (this.props.genderCodes) {
      let temp = this.props.genderCodes;
      temp.forEach(e => {
        genderCodes.push({
          value: e.systemCode,
          label: e.name
        });
      });
    }
    let countryCode = this.props.initialValues.countryCode;
    let countryCodes = [];
    if (this.props.countryCodes) {
      let codes = this.props.countryCodes;
      codes.forEach(e => {
        countryCodes.push({
          value: e.name,
          label: e.name
        });
      });
    }
    return (
      <form
        name="form"
        onSubmit={handleSubmit}
        ref={form => {
          this.form = form;
        }}
        className="row form"
      >
        <div className="col-12">
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey={recaptchaKey}
          />
          <Field
            name="salutation"
            type="text"
            parse={option => (option ? option.value : null)}
            options={salutationOptions}
            key={currentLanguage}
            searchable={false}
            disabled={submitting}
            component={SelectField}
            label={translate("_shared.form.label._salutation")}
            className="mb-4"
          />
          <Field
            name="firstName"
            type="text"
            autocomplete="given-name"
            required={true}
            disabled={submitting}
            component={TextField}
            label={translate("_shared.form.label._firstName")}
            className="mb-4"
          />
          <Field
            name="lastName"
            type="text"
            autocomplete="family-name"
            required={true}
            disabled={submitting}
            component={TextField}
            label={translate("_shared.form.label._lastName")}
            className="mb-4"
          />
          <Field
            name="email"
            type="email"
            autocomplete="email"
            required={true}
            validate={this.validateEmail}
            disabled={submitting}
            component={TextField}
            label={translate("_shared.form.label._email")}
            maxLengthEmail={64}
            className="mb-2"
            onBlur={this.checkEmail}
          />
          <p className="pb-2 textbox_sublabel">
            The email is used for password retrieval. Please make sure that it’s
            a valid one.
          </p>
          <label style={{ fontSize: 14 }}>Mobile Number *</label>
          <div className="row mx--2 mb-2">
            <div className="px-2 mb-3 col-3 col-md-3">
              <Field
                name="countryCode"
                type="text"
                parse={option => (option ? option.value : null)}
                options={countryCodes}
                value={countryCode}
                disabled={submitting}
                required={true}
                hideLabel={true}
                component={SelectField}
              />
            </div>
            <div className=" px-2 mb-3  col-9 col-md-6">
              <Field
                name="mobile"
                type="number"
                autocomplete="country-code"
                required={true}
                hideLabel={true}
                label={translate("_shared.form.label._mobileNo")}
                component={TextField}
              />
            </div>
            <div className=" px-2 mb-3  col-9 col-md-3">
              <Button
                onClick={this.handleSendOtp}
                type="button"
                className="btn btn-primary"
                disabled={this.state.isDisabled}
              >
                GET OTP
              </Button>
            </div>
          </div>

          {this.state.showOTP && (
            <div>
              <p className="textbox_label">Enter OTP sent to your mobile*</p>
              <OtpInput
                name="otpCode"
                onChange={otp => {
                  this.setState({ otp: otp });
                  this.props.onChangeOTP(otp);
                }}
                numInputs={6}
                inputStyle="input-otp"
                containerStyle="mb-2"
                value={this.state.otp}
              />
            </div>
          )}
          <p className="textbox_label my-2">Birthday*</p>
          <Field
            name="dob"
            required={true}
            disabled={submitting}
            component={DatePickerField}
            disabledYear={false}
            label={translate("_shared.form.label._dob")}
          />

          <div>
            <Field
              name="gender"
              type="text"
              required={true}
              parse={option => (option ? option.value : null)}
              options={genderCodes}
              key={currentLanguage}
              searchable={false}
              value={gender}
              component={SelectField}
              label={translate("_shared.form.label._genderCode")}
              className="mb-4"
            />
          </div>
          <div className="input-pw">
            <Field
              name="password"
              type={this.state.type}
              autocomplete="new-password"
              component={TextField}
              required={true}
              disabled={submitting}
              minLength={8}
              label={translate("_shared.form.label._password")}
              className="mb-4"
            />
            <span className="password__show" onClick={this.showHide}>
              {this.state.type === "input" ? (
                <i class="fa fa-eye"></i>
              ) : (
                <i class="fa fa-eye-slash"></i>
              )}
            </span>
          </div>
          <div className="textbox_sublabel mb-4 mt--3">
            <p className="pw-must mt-2">Password must:</p>
            <Translate id="signUp.form._passwordRules" />
          </div>
          <div className="input-pw">
            <Field
              name="confirmPassword"
              type={this.state.typecpw}
              component={TextField}
              required={true}
              disabled={submitting}
              match={translate("_shared.form.label._password")}
              label={translate("_shared.form.label._confirmPassword")}
              className="mb-4"
            />
            <span className="password__show" onClick={this.showHideCpw}>
              {this.state.typecpw === "input" ? (
                <i class="fa fa-eye"></i>
              ) : (
                <i class="fa fa-eye-slash"></i>
              )}
            </span>
          </div>
          <div className="row px-3">
            <Field
              id="tncAgree"
              name="tncAgree"
              type="checkbox"
              disabled={submitting}
              component={CheckField}
              label={
                <span style={styleTncAgree}>
                  By signing up as a &Rewards member, I agree to the
                  <Link to={`/policy`} className="link link-primary">
                    {" "}
                    Privacy Policy{" "}
                  </Link>
                  and
                  <Link to={`/terms`} className="link link-primary">
                    {" "}
                    Terms & Conditions{" "}
                  </Link>
                  of the site.
                </span>
              }
            />
          </div>
          <br />
          <p style={styleTncAgree}>
            By selecting the options below, I agree to give consensus to receive
            information, news, offers and promotions from RE&S via:
          </p>
          <div className="row px-3 color-text-2">
            <Field
              id="smsSubscription"
              name="smsSubscription"
              type="checkbox"
              disabled={submitting}
              component={CheckField}
              label={"SMS"}
              className="font-sub mr-2"
            />
          </div>
          <div className="row px-3 color-text-2">
            <Field
              id="emailSubscription"
              name="emailSubscription"
              type="checkbox"
              disabled={submitting}
              component={CheckField}
              label={"Email"}
              className="font-sub mr-2"
            />
          </div>
          {error && (
            <div className="my-2 text-left">
              <span className="form-error">{error}</span>
            </div>
          )}
        </div>

        <div className="col-12 text-left mt-4">
          <Button
            type="submit"
            className="btn btn-signup"
            disabled={pristine}
            loading={submitting}
          >
            <Translate id={submitting ? "_Submitting" : "_sign_up"} />
          </Button>
        </div>
      </form>
    );
  }
}

const styleTncAgree = {
  fontSize: "0.8rem"
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const selector = formValueSelector("sign-up");

const mapStateToProps = state => ({
  translate: getTranslate(state.locale),
  currentLanguage: getActiveLanguage(state.locale).code,
  mobile: selector(state, "mobile"),
  countryCode: selector(state, "countryCode"),
  activeBtnOTP: state.otp.activeBtn,
  countryCodes: state.systemCodes.countryCodes,
  genderCodes: state.systemCodes.genderCodes
});

const mapDispatchToProps = dispatch => ({
  getSysCodeOptions: bindActionCreators(getSysCodeOptions, dispatch)
});

export default reduxForm({
  form: formId, // a unique identifier for this form
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false
})(connect(mapStateToProps, mapDispatchToProps)(SignUpForm));
