const LangForgotPassword = {
  "forgotPassword._title": ["Trouble Logging In?", "QUÊN MẬT KHẨU"],
  "forgotPassword.form.button._resetMyPassword": [
    "Reset Password",
    "Thay đổi mật khẩu"
  ],
  "forgotPassword._description": [
    `Enter your registered email address and we'll send you a link to reset your password.`,
    `Vui lòng nhập địa chỉ email bạn đã đăng ký tại đây. Chúng tôi sẽ gửi thông tin hướng dẫn bạn cách chọn lại mật khẩu.`
  ]
};

export default LangForgotPassword;
