import apiClient from "../../helpers/apiClient";

export const GET_PRIZES = "GET_PRIZES";
export const GET_PRIZES_REQUEST = "GET_PRIZES_REQUEST";
export const GET_PRIZES_SUCCESS = "GET_PRIZES_SUCCESS";
export const GET_PRIZES_FAILURE = "GET_PRIZES_FAILURE";

export const GET_NUMBER_OF_CHANCES = "GET_NUMBER_OF_CHANCES";
export const GET_NUMBER_OF_CHANCES_REQUEST = "GET_NUMBER_OF_CHANCES_REQUEST";
export const GET_NUMBER_OF_CHANCES_SUCCESS = "GET_NUMBER_OF_CHANCES_SUCCESS";
export const GET_NUMBER_OF_CHANCES_FAILURE = "GET_NUMBER_OF_CHANCES_FAILURE";

export const GET_LOCK_CHANCE = "GET_LOCK_CHANCE";
export const GET_LOCK_CHANCE_REQUEST = "GET_LOCK_CHANCE_REQUEST";
export const GET_LOCK_CHANCE_SUCCESS = "GET_LOCK_CHANCE_SUCCESS";
export const GET_LOCK_CHANCE_FAILURE = "GET_LOCK_CHANCE_FAILURE";

export const GET_AND_REDEEM_PRIZE_WON = "GET_AND_REDEEM_PRIZE_WON";
export const GET_AND_REDEEM_PRIZE_WON_REQUEST =
  "GET_AND_REDEEM_PRIZE_WON_REQUEST";
export const GET_AND_REDEEM_PRIZE_WON_SUCCESS =
  "GET_AND_REDEEM_PRIZE_WON_SUCCESS";
export const GET_AND_REDEEM_PRIZE_WON_FAILURE =
  "GET_AND_REDEEM_PRIZE_WON_FAILURE";

const initialState = {
  data: {},
  errorMessage: ""
};

export const getNumberOfChances = chanceId => dispatch => {
  return dispatch({
    types: [
      GET_NUMBER_OF_CHANCES_REQUEST,
      GET_NUMBER_OF_CHANCES_SUCCESS,
      GET_NUMBER_OF_CHANCES_FAILURE
    ],
    callAPI: config => apiClient.get(`SpinWheel/GetNumberOfChances`, config),
    payload: {}
  });
};

export const getPrizes = () => dispatch => {
  return dispatch({
    types: [GET_PRIZES_REQUEST, GET_PRIZES_SUCCESS, GET_PRIZES_FAILURE],
    callAPI: config => apiClient.get(`SpinWheel/GetPrizes`, config),
    payload: {}
  });
};

export const getLockChance = () => dispatch => {
  return dispatch({
    types: [
      GET_LOCK_CHANCE_REQUEST,
      GET_LOCK_CHANCE_SUCCESS,
      GET_LOCK_CHANCE_FAILURE
    ],
    callAPI: config => apiClient.get(`SpinWheel/LockChance`, config),
    payload: {}
  });
};

export const getAndRedeemPrizeWon = chanceId => dispatch => {
  return dispatch({
    types: [
      GET_AND_REDEEM_PRIZE_WON_REQUEST,
      GET_AND_REDEEM_PRIZE_WON_SUCCESS,
      GET_AND_REDEEM_PRIZE_WON_FAILURE
    ],
    callAPI: config =>
      apiClient.get(
        `SpinWheel/GenerateAndRedeemPrizeWon?chanceId=${chanceId}`,
        config
      ),
    payload: {}
  });
};

export default function reducer(state = initialState, action) {
  const { errorMessage, response } = action;
  switch (action.type) {
    case GET_NUMBER_OF_CHANCES_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case GET_NUMBER_OF_CHANCES_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case GET_NUMBER_OF_CHANCES_SUCCESS:
      return {
        ...state,
        numberOfChances: response.data.result
      };
    case GET_PRIZES_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case GET_PRIZES_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case GET_PRIZES_SUCCESS:
      return {
        ...state,
        prizes: response.data.result
      };
    case GET_LOCK_CHANCE_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case GET_LOCK_CHANCE_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case GET_LOCK_CHANCE_SUCCESS:
      return {
        ...state,
        lockChance: response.data.result
      };
    case GET_AND_REDEEM_PRIZE_WON_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case GET_AND_REDEEM_PRIZE_WON_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case GET_AND_REDEEM_PRIZE_WON_SUCCESS:
      return {
        ...state,
        prizeWon: response.data
      };
    default:
      return state;
  }
}
