const LangResetPassword = {
	"resetPassword._title": ["RESET PASSWORD", "THAY ĐỔI MẬT KHẨU"],
	"resetPassword.form.label._newPassword": ["New Password", "Mật khẩu mới"],
	"resetPassword.form.label._confirmNewPassword": [
		"Confirm New Password",
		"Nhập lại mật khẩu mới",
	],
}

export default LangResetPassword
