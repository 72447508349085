import React from "react";
import * as signalR from "@aspnet/signalr";
import { linkHub } from "../configs/Settings";
import Cookies from "js-cookie";
import {
  sendInAppMessage,
  receiveNewStamp,
  fetchExpiringCard,
  pendingReplyApi,
  inFiveMinutes,
  saveEvents
} from "../redux/modules/Hub";
import { getStore } from "../index";
import { showConfirmMessage, showDecisionModal } from "../redux/modules/Modal";
import { push } from "connected-react-router";
import {
  getCurrentCard,
  getCardById1,
  getCardById2
} from "../redux/modules/Stamp";
import { getPendingDecisionById } from "../redux/modules/Decision";
import { fetchNotifications } from "../redux/modules/Notification";
import moment from "moment";
import { getCookie, setCookie } from "./cookieHelper";
import { LAST_EVENT } from "../constants";

var connection = null;

export const createConnectHub = () => {
  connection = new signalR.HubConnectionBuilder()
    .withUrl(`${linkHub}/hub/notification`)
    .configureLogging(signalR.LogLevel.Information)
    .build();
};
export const startConnectHub = () => {
  let jwtToken = Cookies.get("token");
  connection.on("ReceiveNewStamp", data => {
    console.log("connectHub_receiveNewStamp:", data);
    let time = moment().format();
    let time1 = moment()
      .subtract(5, "minutes")
      .format();
    let eventTime = getCookie(LAST_EVENT);
    let isAfter = moment(eventTime).isAfter(time1, "minutes");
    setCookie(LAST_EVENT, time, 999);
    getStore().dispatch(inFiveMinutes(isAfter));
    if (
      getStore().getState().hubs &&
      !getStore().getState().hubs.pendingEvent
    ) {
      if (getStore().getState().hubs && getStore().getState().hubs.events) {
        if (getStore().getState().hubs.events.length > 0) {
          getStore().dispatch(pendingReplyApi(true));
        } else {
          getStore().dispatch(pendingReplyApi(false));
        }
      }
    }
    getStore().dispatch(saveEvents({ data: data, type: "stamp" }));
  });
  connection.on("SentInAppMessage", data => {
    let time = moment().format();
    let time1 = moment()
      .subtract(5, "minutes")
      .format();
    let eventTime = getCookie(LAST_EVENT);
    let isAfter = moment(eventTime).isAfter(time1, "minutes");
    setCookie(LAST_EVENT, time, 999);
    getStore().dispatch(inFiveMinutes(isAfter));
    getStore()
      .dispatch(sendInAppMessage(""))
      .then(() => {
        getStore()
          .dispatch(sendInAppMessage(data))
          .then(() => {
            let fliterTo = moment().format();
            let filterFrom = moment()
              .subtract(6, "months")
              .format();
            let memberId = getStore().getState().profile.data.memberID;
            let param = {
              DeviceUID: memberId,
              AppName: "RES",
              BlastID: "",
              BlastHeaderID: "",
              FilterFrom: filterFrom,
              FilterTo: fliterTo
              // SkipCount: 0,
              // MaxResultCount: 1000
            };
            getStore().dispatch(fetchNotifications(param));
          });
      });
  });
  connection.on("ExpiringCard", (memberId, decisionId) => {
    console.log("connectHub_expiringCard:", memberId + "-" + decisionId);
    let time = moment().format();
    let time1 = moment()
      .subtract(5, "minutes")
      .format();
    let eventTime = getCookie(LAST_EVENT);
    let isAfter = moment(eventTime).isAfter(time1, "minutes");
    setCookie(LAST_EVENT, time, 999);
    getStore().dispatch(inFiveMinutes(isAfter));
    let data = {
      memberId: memberId,
      decisionId: decisionId
    };
    if (
      getStore().getState().hubs &&
      !getStore().getState().hubs.pendingEvent
    ) {
      if (getStore().getState().hubs && getStore().getState().hubs.events) {
        if (getStore().getState().hubs.events.length > 0) {
          getStore().dispatch(pendingReplyApi(true));
        } else {
          getStore().dispatch(pendingReplyApi(false));
        }
      }
    }
    getStore().dispatch(saveEvents({ data: data, type: "card" }));
  });
  connection
    .start()
    .then(() => connection.invoke("Connect", jwtToken))
    .catch(err => console.error(err.toString()));
};
export const disconnectHub = () => {
  if (connection) {
    connection.stop();
    connection = null;
  }
};

export default { startConnectHub, disconnectHub, createConnectHub };
