import Loadable from "react-loadable";
import Loading from "../views/components/shared/Loading";

import LogOut from "../views/pages/LogOut";
import NotFound from "../views/pages/NotFound";
import About from "../views/pages/About";

const AsyncSignIn = Loadable({
    loader: () => import("../views/pages/SignIn"),
    loading: Loading
  }),
  AsyncVerification = Loadable({
    loader: () => import("../views/pages/Verification"),
    loading: Loading
  }),
  AsyncNotifications = Loadable({
    loader: () => import("../views/pages/Notifications"),
    loading: Loading
  }),
  AsyncNotificationDetail = Loadable({
    loader: () => import("../views/pages/NotificationDetail"),
    loading: Loading
  }),
  AsyncMyWallet = Loadable({
    loader: () => import("../views/pages/MyWallet"),
    loading: Loading
  }),
  AsyncMyWalletDetail = Loadable({
    loader: () => import("../views/pages/MyWalletDetail"),
    loading: Loading
  }),
  AsyncScanQrCode = Loadable({
    loader: () => import("../views/pages/ScanQrCode"),
    loading: Loading
  }),
  AsyncUnregisterVerification = Loadable({
    loader: () => import("../views/pages/UnregisterVerification"),
    loading: Loading
  }),
  AsyncCSOLogin = Loadable({
    loader: () => import("../views/pages/CSOLogin"),
    loading: Loading
  }),
  AsyncSignUp = Loadable({
    loader: () => import("../views/pages/SignUp"),
    loading: Loading
  }),
  AsyncForgotPassword = Loadable({
    loader: () => import("../views/pages/ForgotPassword"),
    loading: Loading
  }),
  AsyncAccount = Loadable({
    loader: () => import("../views/pages/Account"),
    loading: Loading
  }),
  AsyncContactUs = Loadable({
    loader: () => import("../views/pages/ContactUs"),
    loading: Loading
  }),
  AsyncTransactions = Loadable({
    loader: () => import("../views/pages/CardTransactions"),
    loading: Loading
  }),
  AsyncRewards = Loadable({
    loader: () => import("../views/pages/Rewards"),
    loading: Loading
  }),
  AsyncProfile = Loadable({
    loader: () => import("../views/pages/Profile"),
    loading: Loading
  }),
  AsyncSpinWheel = Loadable({
    loader: () => import("../views/pages/SpinWheel"),
    loading: Loading
  }),
  AsyncCardDetails = Loadable({
    loader: () => import("../views/pages/CardDetails"),
    loading: Loading
  }),
  AsyncAddCard = Loadable({
    loader: () => import("../views/pages/AddCard"),
    loading: Loading
  }),
  AsyncChangePassword = Loadable({
    loader: () => import("../views/pages/ChangePassword"),
    loading: Loading
  }),
  AsyncStores = Loadable({
    loader: () => import("../views/pages/Stores"),
    loading: Loading
  }),
  AsyncTransactionDetails = Loadable({
    loader: () => import("../views/pages/TransactionDetails"),
    loading: Loading
  }),
  AsyncResetPassword = Loadable({
    loader: () => import("../views/pages/ResetPassword"),
    loading: Loading
  }),
  AsyncTransferBalance = Loadable({
    loader: () => import("../views/pages/TransferBalance"),
    loading: Loading
  }),
  AsyncReportLossPage = Loadable({
    loader: () => import("../views/pages/ReportLoss"),
    loading: Loading
  }),
  AsyncVerifyGoldCardAddressPage = Loadable({
    loader: () => import("../views/pages/VerifyGoldCardAddress"),
    loading: Loading
  }),
  AsyncTermsCondition = Loadable({
    loader: () => import("../views/pages/TermsConditions"),
    loading: Loading
  }),
  AsyncPrivacyPolicy = Loadable({
    loader: () => import("../views/pages/PrivacyPolicy"),
    loading: Loading
  }),
  AsyncFaqs = Loadable({
    loader: () => import("../views/pages/Faqs"),
    loading: Loading
  }),
  AsyncPromotion = Loadable({
    loader: () => import("../views/pages/Promotion"),
    loading: Loading
  }),
  AsyncPromotionDetail = Loadable({
    loader: () => import("../views/pages/PromotionDetail"),
    loading: Loading
  }),
  AsyncHowToUse = Loadable({
    loader: () => import("../views/pages/HowToUse"),
    loading: Loading
  }),
  AsyncCatalogue = Loadable({
    loader: () => import("../views/pages/Catalogue"),
    loading: Loading
  }),
  AsyncRewardsCard = Loadable({
    loader: () => import("../views/pages/RewardsCard"),
    loading: Loading
  }),
  AsyncRedeemPromocode = Loadable({
    loader: () => import("../views/pages/RedeemPromocode"),
    loading: Loading
  }),
  AsyncByodQrCode = Loadable({
    loader: () => import("../views/pages/BYODQrCode"),
    loading: Loading
  });

const Routes = [
  {
    path: `/sign-in`,
    isPrivate: false,
    component: AsyncSignIn
  },
  // {
  //   path: `/cards`,
  //   isPrivate: false,
  //   component: AsyncCards
  // },
  {
    path: `/sign-up`,
    isPrivate: false,
    component: AsyncSignUp
  },
  {
    path: `/forgot-password`,
    isPrivate: false,
    component: AsyncForgotPassword
  },
  {
    path: `/reset-password/:mid/:ts`,
    isPrivate: false,
    component: AsyncResetPassword
  },
  {
    path: `/verification/:mid/:cn/:ts`,
    isPrivate: false,
    component: AsyncVerification
  },
  {
    path: `/unregisterVerification/:mid/:cn`,
    isPrivate: false,
    component: AsyncUnregisterVerification
  },
  {
    path: `/csologin/:Param`,
    isPrivate: false,
    component: AsyncCSOLogin
  },
  {
    path: `/policy`,
    isPrivate: false,
    component: AsyncPrivacyPolicy
  },
  {
    path: `/terms`,
    isPrivate: false,
    component: AsyncTermsCondition
  },
  {
    path: `/contact-us`,
    isPrivate: false,
    component: AsyncContactUs
  },
  {
    path: `/faqs`,
    isPrivate: false,
    component: AsyncFaqs
  }
];

const PrivateRoutes = [
  {
    path: `/account`,
    isPrivate: true,
    component: AsyncAccount
  },
  {
    path: `/terms`,
    isPrivate: true,
    component: AsyncTermsCondition
  },
  {
    path: `/policy`,
    isPrivate: true,
    component: AsyncPrivacyPolicy
  },
  {
    path: `/faqs`,
    isPrivate: true,
    component: AsyncFaqs
  },
  {
    path: `/contact-us`,
    isPrivate: true,
    component: AsyncContactUs
  },
  {
    path: `/how-to-use`,
    isPrivate: true,
    component: AsyncHowToUse
  },
  {
    path: `/promotion`,
    isPrivate: true,
    component: AsyncPromotion
  },
  {
    path: `/catalogue`,
    isPrivate: true,
    component: AsyncCatalogue
  },
  {
    path: `/promotion-detail`,
    isPrivate: true,
    component: AsyncPromotionDetail
  },
  {
    path: `/transactions`,
    isPrivate: true,
    exact: true,
    component: AsyncTransactions
  },
  {
    path: `/transactions/:cardNo`,
    isPrivate: true,
    exact: true,
    component: AsyncTransactions
  },
  {
    path: `/transactions/:receiptNo/:autoID`,
    isPrivate: true,
    component: AsyncTransactionDetails
  },
  {
    path: `/spin-wheel`,
    isPrivate: true,
    exact: true,
    component: AsyncSpinWheel
  },
  {
    path: `/stores`,
    isPrivate: true,
    exact: true,
    component: AsyncStores
  },
  {
    path: `/cards/transfer/:cardNo`,
    isPrivate: true,
    component: AsyncTransferBalance
  },
  {
    path: `/cards/add-card`,
    isPrivate: true,
    component: AsyncAddCard
  },
  {
    path: `/cards/report-loss/:cardNo`,
    isPrivate: true,
    component: AsyncReportLossPage
  },
  {
    path: `/cards/verify-gold-card-address`,
    isPrivate: true,
    component: AsyncVerifyGoldCardAddressPage
  },
  {
    path: `/cards/:cardNo`,
    isPrivate: true,
    component: AsyncCardDetails
  },
  {
    path: `/rewards`,
    isPrivate: true,
    component: AsyncRewards
  },
  {
    path: `/profile`,
    isPrivate: true,
    exact: true,
    component: AsyncProfile
  },
  {
    path: `/change-password`,
    isPrivate: true,
    component: AsyncChangePassword
  },
  {
    path: `/rewards-card`,
    isPrivate: true,
    component: AsyncRewardsCard
  },
  {
    path: `/notifications`,
    isPrivate: true,
    component: AsyncNotifications
  },
  {
    path: `/notification-detail`,
    isPrivate: true,
    component: AsyncNotificationDetail
  },
  {
    path: `/my-wallet`,
    isPrivate: true,
    component: AsyncMyWallet
  },
  {
    path: `/my-wallet-detail`,
    isPrivate: true,
    component: AsyncMyWalletDetail
  },
  {
    path: `/scan-qr-code`,
    isPrivate: true,
    component: AsyncScanQrCode
  },
  {
    path: `/about`,
    isPrivate: true,
    component: About
  },
  {
    path: `/redeem-promocode`,
    isPrivate: true,
    component: AsyncRedeemPromocode
  },
  {
    path: `/mobile-ordering`,
    isPrivate: true,
    component: AsyncByodQrCode
  }
];

const SharedRoutes = [
  {
    path: `/logout`,
    isPrivate: true,
    component: LogOut
  },
  {
    path: ``,
    exact: true,
    isPrivate: false,
    component: AsyncSignIn
  },
  {
    path: `/*`,
    isPrivate: false,
    status: 404,
    component: NotFound
  }
];

export { Routes, PrivateRoutes, SharedRoutes };
