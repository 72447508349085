const LangTransactionDetails = {
	"transactionDetails._title": ["MY TRANSACTION DETAILS", "CHI TIẾT GIAO DỊCH"],
	"transactionDetails.headers.transactionId": ["TRANSACTION ID", "SỐ HÓA ĐƠN"],
	"transactionDetails.headers.receiptId": ["RECEIPT ID", "Số hóa đơn"],
	"transactionDetails.headers.dateAndTime": ["DATE AND TIME", "NGÀY GIỜ"],
	"transactionDetails.headers.store": ["STORE", "CỬA HÀNG"],
	"transactionDetails.headers.starsEarned": ["STARS EARNED", "ĐIỂM NGÔI SAO"],
	"transactionDetails.headers.bonusStars": ["BONUS STARS", "NGÔI SAO THƯỞNG"],
	"transactionDetails.headers.cardNo": ["CARD NUMBER", "SỐ THẺ"],
	"transactionDetails.headers.title": ["TITLE", "CHI TIẾT"],
	"transactionDetails.headers.amount": ["AMOUNT", "SỐ TIỀN"],
	"transactionDetails.headers.payment": ["PAYMENT", "THANH TOÁN"],
	"transactionDetails.bottom.total": ["TOTAL", "TỔNG CỘNG"],
	"transactionDetails.bottom.discount": ["Discount", "Giảm giá"],
	"transactionDetails.params._invalidReceiptNo": [
		"Oops! Something went wrong",
		"Đang có sự cố xảy ra",
	],
	"transactionDetails.type.SALES": ["In Store Purchase", "In Store Purchase"],
	"transactionDetails.type.PLUS ADJUSTMENT": ["In Store Reload", "In Store Reload"],
	"transactionDetails.type.TOP UP": ["Reload SVC Card", "Reload SVC Card"],
	"transactionDetails.type.MINUS ADJUSTMENT": [
		"MINUS ADJUSTMENT",
		"vi_MINUS ADJUSTMENT",
	],
	"transactionDetails.type.POINTS REDEMPTION": [
		"POINTS REDEMPTION",
		"vi_POINTS REDEMPTION",
	],
	"transactionDetails.type.REFUND SALES ITEMS": [
		"REFUND SALES ITEMS",
		"vi_REFUND SALES ITEMS",
	],
	"transactionDetails.type.POINTS REWARD FOR ITEM": [
		"POINTS REWARD FOR ITEM",
		"vi_POINTS REWARD FOR ITEM",
	],
	"transactionDetails.type.ITEM REDEMPTION": ["ITEM REDEMPTION", "vi_ITEM REDEMPTION"],
	"transactionDetails.type.VOUCHER REDEMPTION": [
		"Reward Redemption",
		"Reward Redemption",
	],
	"transactionDetails.type.redeemed": ["Redeemed", "Đã đổi quà thưởng"],
	"transactionDetails.type.FUNDS TRANSFER": ["Funds Transfer", "Funds Transfer"],
	"transactionDetails.paymentType.CARD": ["Abbott Card", "Abbott Card"],
	"transactionDetails.paymentType.CREDIT": ["Credit Card", "Credit Card"],
	"transactionDetails.paymentType.CASH": ["Cash", "Cash"],
}

export default LangTransactionDetails
