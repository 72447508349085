const LangSignIn = {
  "signIn.signUp._notAMember": ["NOT A MEMBER?", "Chưa là thành viên?"],
  "signIn.signUp._reward": [
    "Lorem ipsum dolor sit amet",
    "Lorem ipsum dolor sit amet"
  ],
  "signIn.signUp._description": [
    "<p>We reward you for dining with us! Get a step closer to your next reward everytime you have a date, family gathering or business meeting at any of our establishments.<br /><br /><br />Registration is free & simple!</p>",
    "<p>Khi bạn đăng ký thành công thẻ Abbott, bạn đã là thành viên của chương trình Abbott Rewards<sup>TM</sup>.</p><p>Thanh toán bằng thẻ Abbott Rewards<sup>TM</sup> khi thực hiện bất kỳ giao dịch tại cửa hàng Abbott để tích lũy điểm ngôi sao.</p><p>Bạn sẽ được một ngôi sao điểm thưởng với mỗi chi tiêu VND 40,000.</p><p>Tích lũy 100 ngôi sao điểm thưởng trong một năm để được nâng lên hạng thẻ Vàng.</p>"
  ],
  "signIn.form._forgotPasswordOrEmail": ["Forgot your ", "Quên "],
  "signIn.form._email": ["email", "email"],
  "signIn.form._password": ["password", "mật khẩu"],
  "signIn.form._invalidCredential": [
    "Please check your sign-in information and try again. 5 failed attempts before your account will be temporarily locked for 10 mins",
    "Địa chỉ email hoặc mật khẩu không đúng, vui lòng thử lại. Tài khoản của bạn sẽ bị tạm khóa trong vòng 10 phút nếu bạn nhập sai thông tin quá 5 lần."
  ],
  "signIn.signUp._pay": ["Pay with your phone", "Thanh toán với ứng dụng"],
  "signIn.signUp._earn": ["Earn free Drinks and Food", "Bánh và nước miễn phí"],
  "signIn.signUp._exclusive": ["Exclusive Member Offer", "Ưu đãi đặc biệt"]
};

export default LangSignIn;
