import ampClient from "../../helpers/ampClient";
import { ampSettings } from "../../configs/Settings";
import { startSubmit } from "redux-form";

import { showMessageModal } from "./Modal";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const ACTIVE_BUTTON_OTP = "ACTIVE_BUTTON_OTP";

const initialState = {
  data: {},
  didLoaded: false,
  errorMessage: "",
  successMessage: ""
};

export const activeBtnOTP = val => {
  return {
    type: ACTIVE_BUTTON_OTP,
    payload: val
  };
};

export const sendOtp = (phoneNumber, token) => (dispatch, getState) => {
  let MobileNo = phoneNumber.replace("%2B", "+");
  let payload = {
    MobileNo,
    SMSMaskName: ampSettings.smsMaskName,
    SMSMessage: ampSettings.smsMessage,
    Options: ampSettings.options,
    CharLen: parseInt(ampSettings.charLen),
    AppName: ampSettings.appNameBase64,
    CaptchaToken: token
  };
  return Promise.resolve(dispatch(startSubmit("Otp")))
    .then(() =>
      dispatch({
        types: [SEND_OTP_REQUEST, SEND_OTP_SUCCESS, SEND_OTP_FAILURE],
        callAPI: config =>
          ampClient.post(ampSettings.getOtpEndpoint, payload, config),
        payload: {}
      })
    )
    .then(() => dispatch(activeBtnOTP(true)))
    .then(() => {
      return dispatch(
        showMessageModal("Send OTP success. Please check your message")
      );
    })
    .catch(err => {
      return dispatch(
        showMessageModal(`${err.error.response.data.error.message}`)
      );
    });
};

export const validateOtp = (phoneNumber, Otp, callback) => dispatch => {
  let MobileNo = phoneNumber.replace("%2B", "+");
  const payload = {
    MobileNo,
    OTP: Otp,
    IgnoreCase: ampSettings.validateOtpIgnoreCase,
    TimeIntervalType: ampSettings.validateOtpTimeIntervalType,
    TimeIntervalValue: ampSettings.validateOtpTimeIntervalValue,
    AppName: ampSettings.appNameBase64
  };
  return Promise.resolve(dispatch(startSubmit("Otp")))
    .then(() =>
      dispatch({
        types: [
          VALIDATE_OTP_REQUEST,
          VALIDATE_OTP_SUCCESS,
          VALIDATE_OTP_FAILURE
        ],
        callAPI: config =>
          ampClient.post(ampSettings.validateOtpEndpoint, payload, config),
        payload: {}
      })
    )
    .then(callback)
    .catch(err => {
      return Promise.all([
        dispatch(showMessageModal(`${err.error.response.data.error.message}`)),
        dispatch(activeBtnOTP(true))
      ]);
    });
};

export default function reducer(state = initialState, action) {
  const { errorMessage, response, payload } = action;
  switch (action.type) {
    case VALIDATE_OTP_REQUEST:
    case SEND_OTP_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case VALIDATE_OTP_FAILURE:
    case SEND_OTP_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case VALIDATE_OTP_SUCCESS:
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        data: response,
        didLoaded: true
      };
    case ACTIVE_BUTTON_OTP:
      return {
        ...state,
        activeBtn: payload
      };
    default:
      return state;
  }
}
