const LangProfile = {
  "profile.title": ["MY PROFILE", "TÀI KHOẢN CỦA TÔI"],
  "profile.note": [
    "Tap the Edit button below to make changes to your profile",
    "Nhấn vào Edit button để hiệu chỉnh"
  ],
  "profile.subTitle.personalInformation": [
    "PERSONAL INFORMATION",
    "THÔNG TIN CÁ NHÂN"
  ],
  "profile.subTitle.addressInformation": ["ADDRESS INFORMATION", "ĐỊA CHỈ"],
  "profile.link.changePassword": ["Change my password", "Thay đổi mật khẩu"],
  "profile.link.unregisterAccount": [
    "Click here if you want to unregister your account.",
    "Bấm tại đây nếu bạn muốn hủy tài khoản."
  ]
};

export default LangProfile;
