import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTranslate, getActiveLanguage } from "react-localize-redux";
import PropTypes from "prop-types";
import { showIFrameModal } from "../../redux/modules/Modal";
import Logo from "../../images/logo_footer.png";

class Footer extends React.Component {
  renderMenuGroup = ({ title, titlePath, items }, locale, index) => {
    return (
      <div className="col-lg-3 col-md-6 mb-3 mb-lg-0" key={index}>
        <div className="group-heading">
          <a href={titlePath}>{title}</a>
        </div>
        <ul className={`footer-menu ${items.length > 3 ? "col-split-2" : ""}`}>
          {items.map((value, subIndex) =>
            this.renderMenuItems(value, locale, subIndex)
          )}
        </ul>
      </div>
    );
  };

  renderMenuItems = ({ path, title, className }, locale, subIndex) => {
    return (
      <li key={subIndex}>
        <a href={path}>{title}</a>
      </li>
    );
  };

  render() {
    const { currentLanguage, isAuthenticated } = this.props;
    return (
      <footer id="footer" className="container-fluid">
        <div className="copyright row align-items-center">
          <div className="col-sm-8 mb-3">
            {currentLanguage === "en" && (
              <div className="footer-links mb-2">
                <span className="footer-link">
                  <a href="/policy">Privacy Statement</a>
                </span>
                <span className="footer-link mb-2">
                  <a href="/terms">Terms of Use</a>
                </span>
                {/*{isAuthenticated && (*/}
                <span className="footer-link mb-2">
                  <a href="/contact-us">Contact Us</a>
                </span>
                {/*)}*/}
                <span className="footer-link mb-2">
                  <a href="/faqs">&Rewards FAQ</a>
                </span>
                <span className="footer-link mb-2">
                  <a href="/">Return to RE&S homepage</a>
                </span>
              </div>
            )}
            <p className="text-copy-right">
              © {new Date().getFullYear()} RE&S Singapore. All rights reserved.
            </p>
          </div>
          <div className="col-sm-4 footer-logo text-left text-sm-right mt-sm-0 mt-2">
            <img src={Logo} alt="RE&S logo" />
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  location: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

const mapStateToProps = ({ locale, auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  translate: getTranslate(locale),
  currentLanguage: getActiveLanguage(locale).code
});
const mapDispatchToProps = dispatch => ({
  showIFrameModal: bindActionCreators(showIFrameModal, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
