const LangModal = {
  "modal.button._confirm": ["Confirm", "Xác nhận"],
  "modal.button._ok": ["Ok", "OK"],
  "modal.button._cancel": ["Cancel", "Hủy"],
  "modal.button._yes": ["Yes", "Có"],
  "modal.button._no": ["No", "Không"],
  "modal.title._message": [" ", " "]
};

export default LangModal;
