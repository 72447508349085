const LangVerifyGoldCardAddress = {
	"verifyGoldCardAddress.copy._message": [
		"Please confirm your mailing list to recieve gold card.",
		"Vui lòng xác nhận lại địa chỉ để nhận thẻ Vàng.",
	],
	"verifyGoldCardAddress.message._updateAddress": [
		"Your address has been updated.",
		"Địa chỉ đã được cập nhật.",
	],
	"verifyGoldCardAddress._title": ["Verify Mailing Address", "Xác nhận địa chỉ"],
}

export default LangVerifyGoldCardAddress
