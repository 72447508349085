const LangFooter = {
  "footer.footerMenu._Change_Region": ["Change Region", "Thay đổi ngôn ngữ"],
  "footer.footerMenu._English": ["English", "English"],
  "footer.footerMenu._Vietnamese": ["Tiếng Việt (VN)", "Tiếng Việt (VN)"],
  "footer._copyright": [
    "© 2019 RE&S Singapore. All rights reserved.",
    "© 2019 RE&S Singapore. Mọi quyền được bảo lưu."
  ]
};

export default LangFooter;
