import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTranslate, getActiveLanguage } from "react-localize-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "../helpers/polyfills";
import { Routes, PrivateRoutes, SharedRoutes } from "../configs";
import PrivateRoute from "./components/routes/PrivateRoute";
import RouteWithSubRoutes from "./components/routes/RouteWithSubRoutes";
import Layout from "./layout/Layout";
import PrivateLayout from "./layout/PrivateLayout";
import connectHub from "../helpers/connectHub";
import {
  sendInAppMessage,
  receiveNewStamp,
  pendingReplyApi,
  isReload,
  saveEvents
} from "../redux/modules/Hub";
import { bindActionCreators } from "redux";
import { showConfirmMessage, showDecisionModal } from "../redux/modules/Modal";
import { getPendingDecisionById } from "../redux/modules/Decision";
import {
  getCurrentCard,
  getCardById1,
  getCardById2
} from "../redux/modules/Stamp";
import { push } from "connected-react-router";
import moment from "moment";

class App extends PureComponent {
  constructor(props) {
    super(props);
  }
  accessGranted = true;
  async componentDidMount() {
    if (this.props.isAuthenticated) {
      connectHub.createConnectHub();
      connectHub.startConnectHub();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isAuthenticated) {
      connectHub.disconnectHub();
    }
    if (
      (nextProps.events && this.props.events !== nextProps.events) ||
      nextProps.pendingEvent != this.props.pendingEvent
    ) {
      if (
        !nextProps.pendingEvent &&
        nextProps.events &&
        nextProps.events.length > 0
      ) {
        let event = nextProps.events[0];
        this.processEvents(event);
      }
    }
  }
  processEvents = event => {
    let type = event.type;
    let data = event.data;
    if (type === "stamp") {
      this.processStampEvent(data);
    } else if (type === "card") {
      let decisionId = data.decisionId;
      if (decisionId) {
        this.processCardEvent(decisionId);
      }
    }
  };
  processCardEvent = decisionId => {
    this.props
      .getPendingDecisionById(decisionId)
      .then(() => {
        let decision = this.props.decisionById[0];
        if (decision.decisionType == "NEW CARD")
          decision.options = ["Start NEW Card", "Continue CURRENT Card"];

        this.props.showDecisionModal(decision, 1);
      })
      .catch(() => {
        this.props.saveEvents("");
        this.props.pendingReplyApi(false);
        this.props.isReload(false);
      });
  };
  processStampEvent = stamps => {
    let lastCardId = stamps.lastCardId;
    let currentCardId = stamps.currentCardId;
    let message = (
      <div>
        <h3 className="mb-2">{"Congratulations"}</h3>
        <span>{"You have earned some new stamps."}</span>
        <br />
        <span>{" Would you like to check out your card?"}</span>
      </div>
    );
    let isAfter = this.props.isInFiveMinutes;
    let nowUrl = window.location.pathname;
    if (nowUrl == "/rewards-card" && isAfter) {
      if (!lastCardId) {
        this.props.getCurrentCard().then(() => {
          this.props.receiveNewStamp(stamps);
          this.props.isReload(true);
        });
      } else {
        this.props.getCardById1(lastCardId).then(() => {
          this.props.getCardById2(currentCardId).then(() => {
            this.props.receiveNewStamp(stamps);
            this.props.isReload(true);
          });
        });
      }
    } else {
      if (this.props.isSpinWheel) {
        this.props.receiveNewStamp(stamps);
        if (!lastCardId) {
          this.props.getCurrentCard();
        } else {
          this.props.getCardById1(lastCardId).then(() => {
            this.props.getCardById2(currentCardId);
          });
        }
      } else {
        this.props.showConfirmMessage(
          message,
          () => {
            this.props.receiveNewStamp(stamps);
            if (!lastCardId) {
              this.props.getCurrentCard().then(() => {
                this.props.history.push("/rewards-card");
              });
            } else {
              this.props.getCardById1(lastCardId).then(() => {
                this.props.getCardById2(currentCardId).then(() => {
                  this.props.history.push("/rewards-card");
                });
              });
            }
          },
          () => {
            this.props.saveEvents("");
            this.props.pendingReplyApi(false);
          },
          "Yes",
          "No"
        );
      }
    }
  };
  render() {
    const {
      currentLanguage,
      isAuthenticated,
      location,
      keepLogin
    } = this.props;
    if (this.accessGranted)
      return (
        <Layout {...this.props} isAuthenticated={isAuthenticated}>
          {isAuthenticated === false && (
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="fade" timeout={250}>
                <Switch location={location}>
                  {Routes.map((route, index) => (
                    <RouteWithSubRoutes
                      key={index}
                      {...route}
                      isAuthenticated={isAuthenticated}
                      currentLanguage={currentLanguage}
                    />
                  ))}
                  {PrivateRoutes.map((route, index) => (
                    <PrivateRoute
                      key={index}
                      {...route}
                      isAuthenticated={isAuthenticated}
                      currentLanguage={currentLanguage}
                      keepLogin={keepLogin}
                    />
                  ))}
                  {SharedRoutes.map((route, index) => (
                    <RouteWithSubRoutes
                      key={index}
                      {...route}
                      isAuthenticated={isAuthenticated}
                      currentLanguage={currentLanguage}
                    />
                  ))}
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
          {isAuthenticated === true && (
            <PrivateLayout {...this.props}>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="fade"
                  timeout={250}
                >
                  <Switch location={location}>
                    {PrivateRoutes.map((route, index) => (
                      <PrivateRoute
                        key={index}
                        {...route}
                        isAuthenticated={isAuthenticated}
                        currentLanguage={currentLanguage}
                        keepLogin={keepLogin}
                      />
                    ))}
                    {Routes.map((route, index) => (
                      <RouteWithSubRoutes
                        key={index}
                        {...route}
                        isAuthenticated={isAuthenticated}
                        currentLanguage={currentLanguage}
                      />
                    ))}
                    {SharedRoutes.map((route, index) => (
                      <PrivateRoute
                        key={index}
                        {...route}
                        isAuthenticated={isAuthenticated}
                        currentLanguage={currentLanguage}
                      />
                    ))}
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </PrivateLayout>
          )}
        </Layout>
      );
    else return <p> You shall not pass </p>;
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

const mapStateToProps = ({ auth, locale, router, hubs, decision }) => ({
  isAuthenticated: auth.isAuthenticated,
  keepLogin: auth.keepLogin,
  translate: getTranslate(locale),
  location: router.location,
  currentLanguage: getActiveLanguage(locale).code,
  stamps: hubs.stamps,
  pendingEvent: hubs.pendingEvent,
  eventTime: hubs.eventTime,
  isInFiveMinutes: hubs.isInFiveMinutes,
  events: hubs.events,
  isSpinWheel: hubs.isSpinWheel,
  decisionById: decision.decisionById
});
const mapDispatchToProps = dispatch => ({
  sendInAppMessage: bindActionCreators(sendInAppMessage, dispatch),
  showConfirmMessage: bindActionCreators(showConfirmMessage, dispatch),
  receiveNewStamp: bindActionCreators(receiveNewStamp, dispatch),
  getCurrentCard: bindActionCreators(getCurrentCard, dispatch),
  getCardById1: bindActionCreators(getCardById1, dispatch),
  getCardById2: bindActionCreators(getCardById2, dispatch),
  pendingReplyApi: bindActionCreators(pendingReplyApi, dispatch),
  isReload: bindActionCreators(isReload, dispatch),
  getPendingDecisionById: bindActionCreators(getPendingDecisionById, dispatch),
  showDecisionModal: bindActionCreators(showDecisionModal, dispatch),
  saveEvents: bindActionCreators(saveEvents, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
