/* eslint-disable */
const LangShare = {
  _sign_up: ["JOIN NOW", "Đăng ký"],
  _register: ["Register", "tài khoản"],
  _login: ["Sign In", "Đăng nhập"],
  _sign_in: ["Sign In", "Đăng nhập"],
  _Submitting: ["Submitting...", "vi_Submitting..."],
  _Submit: ["Submit", "Gửi"],
  _Cancel: ["Cancel", "Hủy"],
  _enableEdit: ["Edit", "Chỉnh sửa"],
  _Continue: ["Continue", "Tiếp tục"],
  _saveChanges: ["Save Changes", "Lưu thay đổi"],
  _unregister: ["Unregister", "Hủy tài khoản"],
  _Reset: ["Reset", "vi_Reset"],
  _Back: ["Back", "Quay lại"],
  _Reload: ["Reload", "Nạp tiền"],
  _confirmAddress: ["Confirm Address", "Xác nhận địa chỉ"],

  _btnResetPassword: ["Reset Password", "Thay đổi mật khẩu"],
  _changeMyPassword: ["Change Password", "Thay đổi mật khẩu"],
  _submitPromocode: ["Redeem", "Đổi mã khuyến mại"],
  _currencyBalance: ["${currency} ${balance}", "${balance} ${currency}"],
  _Transfer: ["Transfer Balance", "Chuyển tiền"],
  _SetAsDefault: ["Set as Default Card", "Chọn làm thẻ mặc định"],
  "_shared.form._cardNoRules": [
    "No spaces or dashes",
    "Không chừa khoảng cách hoặc gạch giữa"
  ],
  "_shared.form._fileUploadRules": [
    "Accepted FileFormats: *.jpeg, *.jpg, *.gif, *.png, *.pdf",
    "Sử dụng dữ liệu có đuôi *.jpeg, *.jpg, *.gif, *.png, *.pdf"
  ],
  "_shared.form._cardDetails": ["CARD DETAILS", "THÔNG TIN THẺ"],
  "_shared.form.label._firstName": ["First Name", "Tên"],
  "_shared.form.label._lastName": ["Last Name", "Họ"],
  "_shared.form.label._genderCode": ["Gender", "Giới Tính"],
  "_shared.form.label._nationality": ["Nationality", "Quốc Tịch"],
  "_shared.form.label._countryCode": [" ", " "],
  "_shared.form.label._mobileNo": ["Mobile Number", "Điện thoại"],
  "_shared.form.label._dob": ["Date of Birth", "Sinh nhật"],
  "_shared.form.label._day": ["Day", "Ngày"],
  "_shared.form.label._month": ["Month", "Tháng"],
  "_shared.form.label._year": ["Year", "vi_Year"],
  "_shared.form.label._password": ["Password", "Mật khẩu"],
  "_shared.form.label._confirmPassword": [
    "Re-enter Password",
    "Xác nhận mật khẩu"
  ],
  "_shared.form.label._email": ["Email Address", "Địa chỉ email"],
  "_shared.form.label._phone": ["Phone Number", "Số điện thoại"],
  "_shared.form.label._keepSignIn": [
    "Keep me sign in",
    "Lưu thông tin đăng nhập"
  ],
  "_shared.form.label._rememberMe": ["Remember Me", "Remember Me"],
  "_shared.form.label._card": ["Card", "Thẻ"],
  "_shared.form.label._cardNo": ["Card Number", "Số thẻ"],
  "_shared.form.label._printedName": ["Card Name", "Tên thẻ"],
  "_shared.form.label._securityCode": ["Security Code", "Số bảo mật"],
  "_shared.form.label._topic": ["Select Feedback Type", "Chủ đề"],
  "_shared.form.label._dateOfVisit": ["Date Of Visit", "Date Of Visit"],
  "_shared.form.label._visitedOutlet": ["Visited Outlet", "Visited Outlet"],
  "_shared.form.label._message": ["Message", "Tin nhắn"],
  "_shared.form.label._attachment": ["Attachment", "Đính kèm"],
  "_shared.form.label._salutation": ["Salutation", "Danh xưng"],
  "_shared.form.label._maritalStatus": [
    "Marital Status",
    "Tình trạng hôn nhân"
  ],
  "_shared.form.label._nationality": ["Nationality", "Nationality"],
  "_shared.form.label._address": ["Address", "Địa chỉ"],
  "_shared.form.label._address1": ["Address Line 1", "Địa chỉ"],
  "_shared.form.label._address2": ["Address Line 2", "Địa chỉ"],
  "_shared.form.label._postalCode": ["Postal Code", "Postal Code"],
  "_shared.form.label._ward": ["Ward", "Phường"],
  "_shared.form.label._city": ["City", "Thành Phố"],
  "_shared.form.label._district": ["District", "Quận"],
  "_shared.form.label._country": ["Country of Residence", "Quận"],
  "_shared.form.label._recaptcha": ["Captcha", "Mã xác thực"],
  "_shared.form.info._contactCCCtoChangeFieldValue": [
    "This information is mandatory and cannot be changed, please contact our Customer Care for any queries at (028) 7306 7676",
    "Các thông tin này sẽ không thể thay đổi, vui lòng liên hệ Trung Tâm Dịch Vụ Khách Hàng của chúng tôi tại (028) 7306 7676 nếu bạn có bất kỳ thắc mắc"
  ],

  "_shared.form.placeholder._select": ["Select", "Chọn"]
};

export default LangShare;
