const LangForgotEmail = {
	"forgotEmail._title": ["FORGOT YOUR EMAIL", "QUÊN ĐỊA CHỈ EMAIL"],
	"forgotEmail._message": [
		`<p>
			Please contact our Customer Care at
			<span class="d-inline-block">(028) 7306 7676</span>
			or
			<span class="word-break-all">
				https://support.abbott.com.sg/hc/en-us/requests/new
			</span>.
		</p>
		<p>
			We will provide assistance on retrieving login information.
		</p>`,
		`<p>
			Vui lòng liên hệ Trung Tâm Dịch Vụ Khách Hàng của chúng tôi tại
			<span class="d-inline-block">(028) 7306 7676</span>
			hoặc email đến
			<span class="word-break-all">
				https://support.abbott.com.sg/hc/en-us/requests/new
			</span>
		</p>
		<p>
			Chúng tôi sẽ hỗ trợ bạn xem lại thông tin email đã đăng ký
		</p>`,
	],
}

export default LangForgotEmail
