import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { hideModal } from "../../../redux/modules/Modal";
import ModalDecisionBase from "./ModalDecisionBase";
import { Translate } from "react-localize-redux";

class ModalDecision extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ModalDecisionBase
        {...this.props}
        hideModal={this.props.hideModal}
        modalDecision={this.props.modalDecision}
        type={this.props.type}
      >
        {this.props.modalDecision.question}
      </ModalDecisionBase>
    );
  }
}
ModalDecision.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired
};
const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(ModalDecision);
