/* eslint-disable */
const LangErrorMessage = {
  "_shared.form.validate._emailIsNotValid": [
    "Please enter a valid email",
    "Địa chỉ email không hợp lệ"
  ],
  "_shared.form.validate._passwordIsNotValid": [
    "The password must contain at least 8 characters.",
    "Mật khẩu không hợp lệ"
  ],
  "_shared.form._required": ["* Required", "* Vui lòng nhập"],
  "_shared.form.validate._required": [
    "Please enter ${label}",
    "Please enter ${label}"
  ],
  "_shared.form.validate._requiredMobi": [
    "Please enter ${label}",
    "Please enter ${label}"
  ],
  "_shared.form.validate._minLength": [
    "Must be at least 8 characters",
    "Phải dài ít nhất 8 ký tự"
  ],
  "_shared.form.validate._fixedLength": [
    "Must be ${fixedLength} characters",
    "Phải dài ${fixedLength} ký tự"
  ],
  "_shared.form.validate._maxLength": [
    "Must be no more than 8 characters",
    "Không được nhiều hơn 8 ký tự"
  ],
  "_shared.form.validate._maxLengthEmail": [
    "Email must be no more than 64 characters",
    "Email must be no more than 64 characters"
  ],
  "_shared.form.validate._firstNumber": [
    "Please enter valid ${label} ",
    "Please enter valid ${label}"
  ],
  "_shared.form.validate._formatCountryCode": [
    "${label} must be start with “+” character",
    "${label} must be start with “+” character"
  ],
  "_shared.form.validate._isValidCountryCode": [
    "Please enter valid a country code. Eg: +65",
    "Please enter valid a country code. Eg: +65"
  ],
  "_shared.form.validate._integer": [
    "Please fill in number",
    "Vui lòng nhập số"
  ],
  "_shared.form.validate._notMatch": [
    "Do not match ${match}",
    "${match} không khớp"
  ],
  "_shared.form.validate._invalidDate": [
    "The birthday is invalid",
    "Ngày sinh nhật không hợp lệ"
  ],
  "_shared.form.validate._allowFileExtensions": [
    "Please upload FileFormats: *.jpeg, *.jpg, *.gif, *png, *pdf",
    "Vui lòng tải lên các file *.jpeg, *.jpg, *.gif, *png, *pdf"
  ],

  "_shared.form.validate._moreThanMaxFileSize": [
    "Must be not more than ${maxSize} Mb",
    "Dung lượng không quá ${maxSize} Mb"
  ],
  "_shared.form.validate._noSpecialChars": [
    "Please don't use any special characters.",
    "Vui lòng không gõ Tiếng Việt."
  ],
  "_shared.form.validate._noVnChars": [
    "Only enter alphabets with no Vietnamese marks.",
    "Vui lòng nhập thông tin không dấu."
  ],
  "_shared.page.params._invalidCardNo": [
    "Invalid Card No!",
    "Số thẻ không hợp lệ"
  ]
};

export default LangErrorMessage;
