const LangHeader = {
  "header.topMenu._benefits": ["BENEFITS", "QUYỀN LỢI"],
  "header.topMenu._mobile_app": ["MOBILE APP", "ỨNG DỤNG ĐIỆN THOẠI"],
  "header.topMenu._view_card_designs": ["VIEW CARD DESIGNS", "CÁC MẪU THẺ"],
  "header.topMenu._faqs": ["FAQS", "FAQs"],
  "header.topMenu._logout": ["Logout", "Đăng xuất"],
  "header.topMenu._login_signup": [
    "Sign In / Sign Up >",
    "Đăng nhập / Đăng ký >"
  ]
};

export default LangHeader;
