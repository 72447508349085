import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";

export const UPDATE_DEVICE_INFO = "UPDATE_DEVICE_INFO";
export const UPDATE_DEVICE_INFO_REQUEST = "UPDATE_DEVICE_INFO_REQUEST";
export const UPDATE_DEVICE_INFO_SUCCESS = "UPDATE_DEVICE_INFO_SUCCESS";
export const UPDATE_DEVICE_INFO_FAILURE = "UPDATE_DEVICE_INFO_FAILURE";

const initialState = {
  data: {},
  didLoaded: false,
  errorMessage: "",
  successMessage: ""
};

export const loadingUpdateDeviceSelector = createLoadingSelector([
  UPDATE_DEVICE_INFO
]);
export const updateDeviceInfo = data => (dispatch, getState) => {
  const isFetching = loadingUpdateDeviceSelector(getState());
  if (!data || isFetching) return Promise.resolve();
  return dispatch({
    types: [
      UPDATE_DEVICE_INFO_REQUEST,
      UPDATE_DEVICE_INFO_SUCCESS,
      UPDATE_DEVICE_INFO_FAILURE
    ],
    callAPI: config =>
      apiClient.post(
        `MemberAccount/UpdateDeviceInfo?MemberID=${data.MemberID}&AppName=${data.AppName}&AppVersion=${data.AppVersion}&DeviceUID=${data.DeviceUID}&DeviceToken=${data.DeviceToken}&DeviceModel=${data.DeviceModel}&PushAlert=${data.PushAlert}`,
        data,
        config
      ),
    payload: {}
  });
};

export default function reducer(state = initialState, action) {
  const { response } = action;
  switch (action.type) {
    case UPDATE_DEVICE_INFO_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case UPDATE_DEVICE_INFO_SUCCESS:
      return {
        ...state,
        deviceInfo: response.data.result
      };
    case UPDATE_DEVICE_INFO_FAILURE:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    default:
      return state;
  }
}
