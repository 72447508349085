import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";
import { getToken } from "./XsrfToken";
import { showMessageModal } from "./Modal";
import { push } from "connected-react-router";

export const FETCH_VOUCHERS = "FETCH_VOUCHERS";
export const FETCH_VOUCHERS_REQUEST = "FETCH_VOUCHERS_REQUEST";
export const FETCH_VOUCHERS_FAILURE = "FETCH_VOUCHERS_FAILURE";
export const FETCH_ACTIVE_VOUCHERS_SUCCESS = "FETCH_ACTIVE_VOUCHERS_SUCCESS";
export const FETCH_REDEEMED_VOUCHERS_SUCCESS =
  "FETCH_REDEEMED_VOUCHERS_SUCCESS";
export const FETCH_EXPIRED_VOUCHERS_SUCCESS = "FETCH_EXPIRED_VOUCHERS_SUCCESS";

export const FETCH_VOUCHER_TYPE = "FETCH_VOUCHER_TYPE";
export const FETCH_VOUCHER_TYPE_REQUEST = "FETCH_VOUCHER_TYPE_REQUEST";
export const FETCH_VOUCHER_TYPE_SUCCESS = "FETCH_VOUCHER_TYPE_SUCCESS";
export const FETCH_VOUCHER_TYPE_FAILURE = "FETCH_VOUCHER_TYPE_FAILURE";

export const FETCH_VOUCHER_BRANDS = "FETCH_VOUCHER_BRANDS";
export const FETCH_VOUCHER_BRANDS_REQUEST = "FETCH_VOUCHER_BRANDS_REQUEST";
export const FETCH_VOUCHER_BRANDS_SUCCESS = "FETCH_VOUCHER_BRANDS_SUCCESS";
export const FETCH_VOUCHER_BRANDS_FAILURE = "FETCH_VOUCHER_BRANDS_FAILURE";

export const FETCH_VOUCHER_MENU = "FETCH_VOUCHER_MENU";
export const FETCH_VOUCHER_MENU_REQUEST = "FETCH_VOUCHER_MENU_REQUEST";
export const FETCH_VOUCHER_MENU_SUCCESS = "FETCH_VOUCHER_MENU_SUCCESS";
export const FETCH_VOUCHER_MENU_FAILURE = "FETCH_VOUCHER_MENU_FAILURE";

export const FETCH_VOUCHER_MENU_BRAND = "FETCH_VOUCHER_MENU_BRAND";
export const FETCH_VOUCHER_MENU_BRAND_REQUEST =
  "FETCH_VOUCHER_MENU_BRAND_REQUEST";
export const FETCH_VOUCHER_MENU_BRAND_SUCCESS =
  "FETCH_VOUCHER_MENU_BRAND_SUCCESS";
export const FETCH_VOUCHER_MENU_BRAND_FAILURE =
  "FETCH_VOUCHER_MENU_BRAND_FAILURE";

export const GET_TRANSFER_VOUCHER = "GET_TRANSFER_VOUCHER";
export const GET_TRANSFER_VOUCHER_REQUEST = "GET_TRANSFER_VOUCHER_REQUEST";
export const GET_TRANSFER_VOUCHER_SUCCESS = "GET_TRANSFER_VOUCHER_SUCCESS";
export const GET_TRANSFER_VOUCHER_FAILURE = "GET_TRANSFER_VOUCHER_FAILURE";

export const SAVE_VOUCHER_SUCCESS = "SAVE_VOUCHER_SUCCESS";

const initialState = {
  data: {},
  didLoaded: false,
  errorMessage: ""
};

export const loadingVouchersSelector = createLoadingSelector([FETCH_VOUCHERS]);
export const fetchActiveVouchers = () => dispatch => {
  return dispatch(getToken()).then(() => {
    dispatch({
      types: [
        FETCH_VOUCHERS_REQUEST,
        FETCH_ACTIVE_VOUCHERS_SUCCESS,
        FETCH_VOUCHERS_FAILURE
      ],
      callAPI: config => apiClient.get(`Membership/GetVoucherList/1`, config),
      payload: {}
    });
  });
};

export const fetchRedeemedVouchers = () => dispatch => {
  return dispatch(getToken()).then(() => {
    dispatch({
      types: [
        FETCH_VOUCHERS_REQUEST,
        FETCH_REDEEMED_VOUCHERS_SUCCESS,
        FETCH_VOUCHERS_FAILURE
      ],
      callAPI: config => apiClient.get(`Membership/GetVoucherList/2`, config),
      payload: {}
    });
  });
};
export const fetchExpiredVouchers = () => dispatch => {
  return dispatch(getToken()).then(() => {
    dispatch({
      types: [
        FETCH_VOUCHERS_REQUEST,
        FETCH_EXPIRED_VOUCHERS_SUCCESS,
        FETCH_VOUCHERS_FAILURE
      ],
      callAPI: config => apiClient.get(`Membership/GetVoucherList/4`, config),
      payload: {}
    });
  });
};

export const fetchVoucherType = () => dispatch => {
  return dispatch({
    types: [
      FETCH_VOUCHER_TYPE_REQUEST,
      FETCH_VOUCHER_TYPE_SUCCESS,
      FETCH_VOUCHER_TYPE_FAILURE
    ],
    callAPI: config => apiClient.get(`Voucher/GetVoucherType`, config),
    payload: {}
  });
};

export const fetchVoucherBrands = data => dispatch => {
  return dispatch({
    types: [
      FETCH_VOUCHER_BRANDS_REQUEST,
      FETCH_VOUCHER_BRANDS_SUCCESS,
      FETCH_VOUCHER_BRANDS_FAILURE
    ],
    callAPI: config =>
      apiClient.get(`Voucher/GetVoucherType?brand=${data}`, data, config),
    payload: {}
  });
};

export const fetchVoucherMenu = data => dispatch => {
  return dispatch({
    types: [
      FETCH_VOUCHER_MENU_REQUEST,
      FETCH_VOUCHER_MENU_SUCCESS,
      FETCH_VOUCHER_MENU_FAILURE
    ],
    callAPI: config =>
      apiClient.get(`Voucher/GetVoucherType?menu=${data}`, data, config),
    payload: {}
  });
};

export const fetchVoucherMenuBrand = (brand, menu) => dispatch => {
  let param = "";
  if (brand) {
    param += `brand=${brand}`;
  }
  if (menu) {
    param += `&menu=${menu}`;
  }
  let api = "Voucher/GetVoucherType";
  if (param != "") {
    api += "?" + param;
  }
  return dispatch({
    types: [
      FETCH_VOUCHER_MENU_BRAND_REQUEST,
      FETCH_VOUCHER_MENU_BRAND_SUCCESS,
      FETCH_VOUCHER_MENU_BRAND_FAILURE
    ],
    callAPI: config => apiClient.get(api, config),
    payload: {}
  });
};
export const getTransferVoucher = (voucherNo, recipientEmail) => dispatch => {
  return dispatch({
    types: [
      GET_TRANSFER_VOUCHER_REQUEST,
      GET_TRANSFER_VOUCHER_SUCCESS,
      GET_TRANSFER_VOUCHER_FAILURE
    ],
    callAPI: config =>
      apiClient.get(
        `Membership/TransferVoucher?voucherNo=${voucherNo}&recipientEmail=${encodeURIComponent(
          recipientEmail
        )}`,
        config
      ),
    payload: {}
  })
    .then(() => {
      dispatch(fetchActiveVouchers()).then(() => {
        dispatch(
          showMessageModal("Voucher has been gifted!", () => {
            return dispatch(push(`/my-wallet`));
          })
        );
      });
    })
    .catch(err => {
      return dispatch(
        showMessageModal(`${err.error.response.data.error.message}`)
      );
    });
};

export const saveVoucher = voucher => dispatch => {
  dispatch({
    type: SAVE_VOUCHER_SUCCESS,
    payload: { voucher: voucher }
  });
  return Promise.resolve();
};

export default function reducer(state = initialState, action) {
  const { payload, errorMessage, response } = action;
  switch (action.type) {
    case FETCH_VOUCHERS_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case FETCH_ACTIVE_VOUCHERS_SUCCESS:
      return {
        ...state,
        activeVouchers: response.data.result,
        didLoaded: true
      };
    case FETCH_REDEEMED_VOUCHERS_SUCCESS:
      return {
        ...state,
        redeemedVouchers: response.data.result,
        didLoaded: true
      };
    case FETCH_EXPIRED_VOUCHERS_SUCCESS:
      return {
        ...state,
        expiredVouchers: response.data.result,
        didLoaded: true
      };
    case FETCH_VOUCHERS_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case FETCH_VOUCHER_TYPE_SUCCESS:
      return {
        ...state,
        voucherType: response.data.result
      };
    case FETCH_VOUCHER_BRANDS_SUCCESS:
      return {
        ...state,
        voucherBrands: response.data.result
      };
    case FETCH_VOUCHER_MENU_SUCCESS:
      return {
        ...state,
        voucherMenu: response.data.result
      };
    case FETCH_VOUCHER_MENU_BRAND_SUCCESS:
      return {
        ...state,
        voucherMenuBrand: response.data.result
      };
    case GET_TRANSFER_VOUCHER_SUCCESS:
      return {
        ...state,
        transferVoucher: response.data.result
      };
    case SAVE_VOUCHER_SUCCESS:
      return {
        ...state,
        saveVoucher: payload.voucher
      };
    default:
      return state;
  }
}
