const LangAboutUs = {
  "aboutUs._title": ["ABOUT US", "LIÊN HỆ CHÚNG TÔI"],

  "aboutUs.subTitle._howCanWeHelp": [
    "Need some help on your Abbott Rewards™ account?",
    "Bạn cần trợ giúp cho tài khoản Abbott Rewards™?"
  ],

  "aboutUs.form._description": [
    "<p>We welcome all questions, comments and feedback - it helps us make your experience at Abbott the best it can be.</p><p>Please select the topic below that best fits your comment or question, then e-mail us.</p>",
    "<p>Chúng tôi rất mong nhận được góp ý hcủa bạn để giúp chúng tôi mang đến trải nghiệm tốt nhất cho bạn.</p><p>Hãy chọn chủ đề hoặc câu bạn bạn muốn gửi đến chúng tôi.</p>"
  ],

  "aboutUs.form.label._privacyAgreement": [
    "Confirm that I am aware the personal data I submit to Abbott will be used in accordance with the Abbott Privacy Statement",
    "Đồng ý rằng các thông tin cá nhân của tôi sẽ được Abbott sử dụng vá tuân thủ các chính sách bảo mật của công ty."
  ],
  "aboutUs.form.name._privacyAgreement": [
    "Privacy Agreement",
    "Chính sách bảo mật thông tin"
  ],
  "aboutUs.form._privacyAgreementRules": ["", ""],

  "aboutUs._AboutUsTitle": ["ABOUT", ""],
  "aboutUs._howItWorksMessage": [
    "Redeem the stored value in your Abbott Gift Card for purchases across Abbott stores in Singapore.",
    "Redeem the stored value in your Abbott Gift Card for purchases across Abbott stores in Singapore."
  ],

  "aboutUs._whereToPurchase": ["WHERE TO PURCHASE", ""],

  "aboutUs._corporateOrdersTitle": ["CORPORATE ORDERS", ""],

  "aboutUs._corporateOrdersMessage._1": [
    `Since 2010, Abbott has built up an excellent track record in fulfilling voucher redemption programmes, servicing major organizations in Singapore.`,
    ""
  ],
  "aboutUs._corporateOrdersMessage._2": [
    "Our Corporate Sales & Partnerships department provides a one-stop solution tailored to individual business requirements. The redemption process is hassle-free and allows letter holders to conveniently redeem Gift Vouchers at our customer service counters.",
    ""
  ],
  "aboutUs._corporateOrdersMessage._3": [
    "For more information, contact our experienced Corporate Sales & Partnerships officers at 6681 7452 or email us at SG.Corporatesales@alfuttaim.com",
    ""
  ],

  "aboutUs._faqTitle": ["FREQUENTLY ASKED QUESTIONS", ""],

  "aboutUs._faqMessage._1": [
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    ""
  ],
  "aboutUs._faqMessage._2": [
    `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    ""
  ]
};

export default LangAboutUs;
