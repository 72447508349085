const LangSidebar = {
  "sidebar.navigation._account": ["ACCOUNT SUMMARY", "TÓM TẮT TÀI KHOẢN"],
  "sidebar.navigation._rewards": ["MY &REWARDS CARD", "QUÀ THƯỞNG CỦA TÔI"],
  "sidebar.navigation._profile": ["MY PROFILE", "TÀI KHOẢN CỦA TÔI"],
  "sidebar.navigation._cards": ["MY CARDS", "THẺ CỦA TÔI"],
  "sidebar.navigation._wallet": ["MY WALLET", "THẺ CỦA TÔI"],
  "sidebar.navigation._transactions": [" MY TRANSACTIONS", "LỊCH SỬ GIAO DỊCH"],
  "sidebar.navigation._redeemPromocode": ["PROMO CODE", "MÃ KHUYẾN MÃI"],
  "sidebar.navigation._byodQrCode": ["MOBILE ORDERING", "ĐẶT HÀNG QUA DI ĐỘNG"],
  "sidebar.navigation._stores": ["STORES", "CỬA HÀNG"],
  "sidebar.navigation._contactUs": ["CONTACT US", "LIÊN HỆ CHÚNG TÔI NGAY"],
  "sidebar.navigation._promotion": ["PROMOTIONS", "KHUYẾN MÃI"],
  "sidebar.navigation._catalogue": ["CATALOGUE", "KHUYẾN MÃI"],
  "sidebar.navigation._aboutUs": ["ABOUT US", "LIÊN HỆ"],
  "sidebar.navigation._about": ["ABOUT", "THÔNG TIN"],
  "sidebar.navigation._activateCard": ["ACTIVATE CARD", "ACTIVATE CARD"],
  "sidebar.navigation._changePassword": ["CHANGE PASSWORD", "CHANGE PASSWORD"],
  "sidebar.navigation._howtoUse": ["HOW TO USE", "HOW TO USE"],
  "sidebar.navigation._logout": ["LOG OUT", "ĐĂNG XUẤT"],
  "sidebar.navigation._unregister": ["UNREGISTER", "HỦY TÀI KHOẢN"],
  "sidebar.navigation._policy": ["PRIVACY POLICY", "PRIVACY POLICY"],
  "sidebar.navigation._terms": ["TERMS & CONDITIONS", "TERMS & CONDITIONS"],
  "sidebar.navigation._faqs": ["&REWARDS FAQ", "&REWARDS FAQ"],
  "sidebar.navigation._reward": ["&REWARDS", "&REWARDS"],
  "sidebar.navigation._downloadMobile": [
    "Download Mobile App",
    "TẢI ỨNG DỤNG ĐIỆN THOẠI"
  ],
  "sidebar.navigation._havingDifficulties": [
    "HAVING DIFFICULTIES? ",
    "BẠN CẦN GIÚP ĐỠ?"
  ],
  "sidebar.navigation._contact": ["CONTACT", "LIÊN HỆ"],
  "sidebar.navigation._contactCsv": [
    "OUR CUSTOMER SERIVCE NOW",
    "CHÚNG TÔI NGAY"
  ]
};

export default LangSidebar;
