const LangCardDetails = {
	"cardDetails._title": ["MY CARDS", "THẺ CỦA TÔI"],
	"cardDetails.button._transferBalance": ["Transfer Balance", "Chuyển tiền"],
	"cardDetails.reportLostCard": ["Report Lost Card", "Báo mất thẻ"],
	"cardDetails.removeCard": ["Remove Card", "Hủy thẻ"],
	"cardDetails.cards": ["CARDS", "THẺ"],
	"cardDetails.storedValue": ["STORE VALUE", "SỐ DƯ THẺ"],
	"cardDetails.newValue": ["NEW VALUE", "vi_NEW VALUE"],
	"cardDetails.status": ["STATUS", "TÌNH TRẠNG"],
	"cardDetails.defaultCard": ["Default Card", "Thẻ mặc định"],
	"cardDetails.message._removeCardConfirmation": [
		"Are you sure you want to remove this card?",
		"Bạn có chắc muốn hủy thẻ này?",
	],
	"cardDetails.message._hasStoreBalance": [
		"You cards still have balance. Please use it or transfer to another before removing. Do you want to redirect to Transfer Balance page?",
		"Thẻ của bạn vẫn còn số dư. Hãy sử dụng hết số dư trong thẻ hoặc chuyển số dư sang thẻ khác trước khi hủy thẻ. Bạn có muốn chuyển số dư ngay?",
	],
	"cardDetails.message._reportLostCardConfirmation": [
		"Are you sure you want to report this card as lost or stolen?",
		"Bạn có chắc muốn báo mất thẻ này?",
	],
	"cardDetails.message._transferBalanceOfSuspendedCard": [
		"Do you want to transfer the balance of your suspended card?",
		"Bạn có muốn chuyển số dư của thẻ bị báo mất?",
	],
	"cardDetails.message._reportCardIsDefault": [
		"This is default card. Please select other card as default before proceeding to report.",
		"vi_This is default card. Please select other card as default before proceeding to report.",
	],
	"cardDetails.message._verifyAddress": [
		"We can send you a replacement card, please verify your address.",
		"Chúng tôi sẽ gửi thẻ thay thế đến cho bạn, hãy cập nhật địa chỉ tại đây.",
	],
	"cardDetails.transactionHistory": ["Transaction History", "Lịch sử giao dịch"],
}

export default LangCardDetails
