const LangSignUp = {
  "signUp.form._createAccount": ["CREATE AN ACCOUNT", "TẠO TÀI KHOẢN"],
  "signUp.form._passwordRules": [
    "The password must contain at least 8 characters.",
    "The password must contain at least 8 characters."
  ],
  "signUp.form._subtitle": [
    "Using your Abbott Gift Card is easier than ever!\
		Just fill in your details below to create a personal account,\
		do remember to save your password carefully.",
    "Using your Abbott Gift Card is easier than ever!\
		Just fill in your details below to create a personal account,\
		do remember to save your password carefully."
  ],
  "signUp.form._consentAgreementRules": [
    "By clicking this button, I agree to the Abbott Site&nbsp;",
    "Bằng cách bấm nút bên dưới, tôi đã đọc và đồng ý với Abbott&nbsp;"
  ],
  "signUp.form.label._consentAgreementCheck": [
    "I have read and agree to the Terms and Conditions of\
		the Abbott Gift Card program (Click Here to view T&C’s)",
    "I have read and agree to the Terms and Conditions of\
		the Abbott Gift Card program (Click Here to view T&C’s)"
  ],
  "signUp.form.name._consentAgreementCheck": [
    "Consent Agreement Check",
    "Consent Agreement Check"
  ],
  "signUp.form._termOfUse": ["Terms & Conditions", "Điều kiện điều khoản"],
  "signUp.form.label._consentAgreement_": [
    "Sign me up to receive exclusive offers and news\
		from Abbott via Email, SMS and Mail.",
    "Sign me up to receive exclusive offers and news\
		from Abbott via Email, SMS and Mail."
  ],
  "signUp.form.label._consentAgreement": [
    "I do not wish to receive news, special offers and more from Abbott.",
    "Không đồng ý nhận email khuyến mãi từ chương trình."
  ],
  "signUp.form.name._consentAgreement": [
    "Consent Agreement",
    "vi_Consent Agreement"
  ],
  "signUp.form._Whatisthis": ["What is this?", "vi_What is this?"],
  "signUp.form._Scratch": [
    "Scratch off this portion at the back of your card",
    "Vui lòng nhập dãy số dưới lớp cào"
  ]
};

export default LangSignUp;
