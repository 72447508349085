import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { Translate } from "react-localize-redux";

class BaseModal extends Component {
  constructor(props) {
    super(props);

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleAfterOpenModal = this.handleAfterOpenModal.bind(this);
  }

  componentWillMount() {
    ReactModal.setAppElement("#root");
  }

  handleCancelClick() {
    const { onCancelClick } = this.props;
    onCancelClick && onCancelClick();
  }

  handleConfirmClick() {
    const { onConfirmClick } = this.props;
    onConfirmClick && onConfirmClick();
  }

  handleAfterOpenModal() {
    const { onAfterOpenModal } = this.props;
    onAfterOpenModal && onAfterOpenModal();
  }

  render() {
    const {
      showModal,
      title,
      message,
      isOpen,
      confirmButtonText,
      cancelButtonText,
      ...props
    } = this.props;
    return (
      <Translate>
        {(translate, activeLanguage) => (
          <ReactModal
            isOpen={isOpen}
            onAfterOpen={this.handleAfterOpenModal}
            onRequestClose={this.handleCancelClick}
            {...props}
          >
            <div className="modal-header">
              <h3 className="modal-header-title">
                {!title && translate("modal.title._message")}
              </h3>
              {/* <div className="modal-close-mark">
                <button
                  className="btn-close-mark"
                  onClick={this.handleCancelClick}
                />
              </div> */}
            </div>
            <h6 className="modal-body pt-3 text-center">
              {this.props.children}
            </h6>
            <div className="modal-footer">
              <button
                className="btn btn-primary btn-small btn-modal-ok"
                onClick={this.handleConfirmClick}
              >
                {confirmButtonText || translate("modal.button._ok")}
              </button>
              <button
                className="btn btn-small btn-modal-cancel ml-2"
                onClick={this.handleCancelClick}
              >
                {cancelButtonText || translate("modal.button._cancel")}
              </button>
            </div>
          </ReactModal>
        )}
      </Translate>
    );
  }
}

BaseModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onAfterOpenModal: PropTypes.func,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string
};

export default BaseModal;
