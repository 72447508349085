import { getStore } from "../../index";
import moment from "moment";
export const SEND_IN_APP_MESSAGE_SUCCESS = "SEND_IN_APP_MESSAGE_SUCCESS";
export const RECEIVE_NEW_STAMP_SUCCESS = "RECEIVE_NEW_STAMP_SUCCESS";
export const FETCH_EXPIRING_CARD_SUCCESS = "FETCH_EXPIRING_CARD_SUCCESS";
export const PENDING_REPLY_API = "PENDING_REPLY_API";
export const IS_RELOAD = "IS_RELOAD";
export const IN_FIVE_MINUTES = "IN_FIVE_MINUTES";
export const SAVE_EVENTS = "SAVE_EVENTS";
export const SPIN_WHEEL = "SPIN_WHEEL";

const initialState = {
  data: "",
  didLoaded: false,
  errorMessage: "",
  successMessage: ""
};

export const sendInAppMessage = data => dispatch => {
  dispatch({
    type: SEND_IN_APP_MESSAGE_SUCCESS,
    payload: { data: data, errorMessage: "" }
  });
  return Promise.resolve();
};


export const receiveNewStamp = data => dispatch => {
  if (data) {
    let time = moment().format();
    data.time = time;
  }
  dispatch({
    type: RECEIVE_NEW_STAMP_SUCCESS,
    payload: { data: data, errorMessage: "" }
  });
};
export const fetchExpiringCard = data => dispatch => {
  dispatch({
    type: FETCH_EXPIRING_CARD_SUCCESS,
    payload: { data: data, errorMessage: "" }
  });
};
export const pendingReplyApi = data => dispatch => {
  dispatch({
    type: PENDING_REPLY_API,
    payload: { data: data, errorMessage: "" }
  });
};
export const isReload = data => dispatch => {
  dispatch({
    type: IS_RELOAD,
    payload: { data: data, errorMessage: "" }
  });
};
export const inFiveMinutes = data => dispatch => {
  dispatch({
    type: IN_FIVE_MINUTES,
    payload: { data: data }
  });
};
export const spinWheel = data => dispatch => {
  dispatch({
    type: SPIN_WHEEL,
    payload: { data: data }
  });
};

export const saveEvents = data => (dispatch, getState) => {
  let events = [];
  if (getState().hubs.events) {
    events = [...getState().hubs.events];
  }
  if (data == "") {
    events.shift();
  } else {
    events.push(data);
  }
  dispatch({
    type: SAVE_EVENTS,
    payload: { data: events, errorMessage: "" }
  });
};

export default function reducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SEND_IN_APP_MESSAGE_SUCCESS:
      return {
        ...state,
        dataMes: payload.data
      };
    case RECEIVE_NEW_STAMP_SUCCESS:
      return {
        ...state,
        stamps: payload.data
      };
    case FETCH_EXPIRING_CARD_SUCCESS:
      return {
        ...state,
        expiringCard: payload.data
      };
    case PENDING_REPLY_API:
      return {
        ...state,
        pendingEvent: payload.data
      };
    case IS_RELOAD:
      return {
        ...state,
        reload: payload.data
      };
    case IN_FIVE_MINUTES:
      return {
        ...state,
        isInFiveMinutes: payload.data
      };
    case SAVE_EVENTS:
      return {
        ...state,
        events: payload.data
      };
    case SPIN_WHEEL:
      return {
        ...state,
        isSpinWheel: payload.data
      };
    default:
      return state;
  }
}
