/**
 * IMG Logo
 */
import LgRwCard from "../images/RE_Menu_&Rewards.png";
import LgMyWallet from "../images/RE_Menu_My_Wallet.png";
import LgMyTransaction from "../images/RE_Menu_My_Transactions.png";
import LgMyProfile from "../images/RE_Menu_My Profile.png";
import LgCatalogue from "../images/RE_Menu_Catalogue.png";
import LgPromotion from "../images/RE_Menu_Promotions.png";
import LgStore from "../images/RE_Menu_Stores.png";
import LgAbout from "../images/RE_Menu_About.png";
import LgPromoCode from "../images/RE_Menu_Promo_Code.png";
import LgMobileOrdering from "../images/RE_Menu_Mobile_Ordering.png";

/**
 * List of menu items for Top Menu
 */
export const TopMenuLinks = [
  {
    path: "/logout",
    className: "mt-3 py-2 pl-3 header-link",
    exact: true,
    title: "header.topMenu._logout"
  }
];

export const AboutLinks = [
  {
    path: "/about",
    className: "link",
    exact: true,
    title: "sidebar.navigation._about",
    img: LgAbout
  }
];

export const DropdownLinks = [
  {
    path: "/how-to-use",
    className: "link",
    exact: true,
    title: "sidebar.navigation._howtoUse"
  },
  {
    path: "/faqs",
    className: "link",
    exact: true,
    title: "sidebar.navigation._faqs"
  },
  {
    path: "/contact-us",
    className: "link",
    title: "sidebar.navigation._contactUs"
  },
  {
    path: "/terms",
    className: "link",
    exact: true,
    title: "sidebar.navigation._terms"
  },
  {
    path: "/policy",
    className: "link",
    exact: true,
    title: "sidebar.navigation._policy"
  }
];

/**
 * List of menu items for Sidebar Menu
 */
export const SidebarMenuLinks = [
  {
    path: "/rewards-card",
    className: "link",
    title: "sidebar.navigation._rewards",
    items: [],
    img: LgRwCard
  },
  {
    path: "/my-wallet",
    className: "link",
    title: "sidebar.navigation._wallet",
    items: [],
    img: LgMyWallet
  },
  {
    path: "/transactions",
    className: "link",
    title: "sidebar.navigation._transactions",
    items: [],
    img: LgMyTransaction
  },
  {
    path: "/redeem-promocode",
    className: "link",
    title: "sidebar.navigation._redeemPromocode",
    items: [],
    img: LgPromoCode
  },
  {
    path: "/mobile-ordering",
    className: "link",
    title: "sidebar.navigation._byodQrCode",
    items: [],
    img: LgMobileOrdering
  },
  {
    path: "/profile",
    className: "link",
    exact: true,
    title: "sidebar.navigation._profile",
    items: [],
    img: LgMyProfile
  },
  {
    path: "/catalogue",
    className: "link",
    title: "sidebar.navigation._catalogue",
    items: [],
    img: LgCatalogue
  },
  {
    path: "/promotion",
    className: "link",
    title: "sidebar.navigation._promotion",
    items: [],
    img: LgPromotion
  },

  {
    path: "/stores",
    className: "link",
    title: "sidebar.navigation._stores",
    img: LgStore
  },
  {
    path: "/about",
    className: "link",
    title: "sidebar.navigation._aboutUs",
    items: [
      {
        path: "/my-wallet",
        className: "link",
        exact: true,
        title: "News"
      },
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Events & Promotions"
      }
    ]
  }
];

/**
 * List of menu items for Footer menu
 */
export const FooterMenuLinks = [
  {
    title: "About Us",
    titlePath: "#",
    items: [
      {
        path: "#",
        className: "link",
        exact: true,
        title: "CSR & Sustainability"
      },
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Corporate Profile"
      },
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Leadership Team"
      },
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Purpose, Vision and Values"
      },
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Our History"
      },
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Our Global Presence"
      }
    ]
  },
  {
    title: "Media Center",
    titlePath: "#",
    items: [
      {
        path: "#",
        className: "link",
        exact: true,
        title: "News"
      },
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Events & Promotions"
      }
    ]
  },
  {
    title: "Career",
    titlePath: "",
    items: [
      {
        path: "#",
        className: "link",
        exact: true,
        title: "Overview"
      }
    ]
  }
];

export const viFooterMenuLinks = [];
