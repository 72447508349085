import apiClient from "../../helpers/apiClient";
import { startSubmit } from "redux-form";

import { showMessageModal } from "./Modal";

export const UNIQUE_EMAIL = "UNIQUE_EMAIL";
export const UNIQUE_EMAIL_REQUEST = "UNIQUE_EMAIL_REQUEST";
export const UNIQUE_EMAIL_SUCCESS = "UNIQUE_EMAIL_SUCCESS";
export const UNIQUE_EMAIL_FAILURE = "UNIQUE_EMAIL_FAILURE";

export const UNIQUE_PHONE = "UNIQUE_PHONE";
export const UNIQUE_PHONE_REQUEST = "UNIQUE_PHONE_REQUEST";
export const UNIQUE_PHONE_SUCCESS = "UNIQUE_PHONE_SUCCESS";
export const UNIQUE_PHONE_FAILURE = "UNIQUE_PHONE_FAILURE";

export const GET_EMAIL_UPDATE_SUCCESS = "GET_EMAIL_UPDATE_SUCCESS";

const initialState = {
  data: {},
  didLoaded: false,
  errorMessage: "",
  successMessage: ""
};

export const uniqueEmail = email => (dispatch, getState) => {
  let getEmail = email.replace("@", "%40");
  return Promise.resolve(dispatch(startSubmit("uniqueField")))
    .then(() =>
      dispatch({
        types: [
          UNIQUE_EMAIL_REQUEST,
          UNIQUE_EMAIL_SUCCESS,
          UNIQUE_EMAIL_FAILURE
        ],
        callAPI: config =>
          apiClient.get(
            `MemberAccount/IsUniqueField?field=Email&value=${getEmail}`,
            config
          ),
        payload: {}
      })
    )
    .then(resp => {
      let { result } = resp.response.data;
      if (result === false) {
        return dispatch(
          showMessageModal("Sorry, this email is already in use.")
        );
      }
    });
};

export const uniquePhone = (phone, callback) => (dispatch, getState) => {
  let getPhone = phone.replace("+", "%2B");
  return Promise.resolve(dispatch(startSubmit("uniqueField")))
    .then(() =>
      dispatch({
        types: [
          UNIQUE_PHONE_REQUEST,
          UNIQUE_PHONE_SUCCESS,
          UNIQUE_PHONE_FAILURE
        ],
        callAPI: config =>
          apiClient.get(
            `MemberAccount/IsUniqueField?field=Mobile&value=${getPhone}`,
            config
          ),
        payload: {}
      })
    )
    .then(resp => {
      let { result } = resp.response.data;
      if (result === false) {
        return dispatch(
          showMessageModal("Sorry, this mobile number is already in use.")
        );
      } else {
        return callback();
      }
    });
};

export const checkExistEmail = (email, callback) => (dispatch, getState) => {
  let getEmail = email.replace("@", "%40");
  return Promise.resolve(dispatch(startSubmit("uniqueField")))
    .then(() =>
      dispatch({
        types: [
          UNIQUE_EMAIL_REQUEST,
          UNIQUE_EMAIL_SUCCESS,
          UNIQUE_EMAIL_FAILURE
        ],
        callAPI: config =>
          apiClient.get(
            `MemberAccount/IsUniqueField?field=Email&value=${getEmail}`,
            config
          ),
        payload: {}
      })
    )
    .then(resp => {
      let { result } = resp.response.data;
      if (result === false) {
        return dispatch(
          showMessageModal(
            "Email address has been registered. Try another email"
          )
        );
      } else {
        return result;
      }
    });
};

export const getEmailUpdate = email => dispatch => {
  dispatch({
    type: GET_EMAIL_UPDATE_SUCCESS,
    payload: { emailUpdate: email, errorMessage: "" }
  });
  return Promise.resolve();
};

export default function reducer(state = initialState, action) {
  const { payload, errorMessage, response } = action;
  switch (action.type) {
    case UNIQUE_EMAIL_REQUEST:
    case UNIQUE_PHONE_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case UNIQUE_EMAIL_FAILURE:
    case UNIQUE_PHONE_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case UNIQUE_EMAIL_SUCCESS:
      return {
        ...state,
        uniqueEmail: response.data.result
      };
    case UNIQUE_PHONE_SUCCESS:
      return {
        ...state,
        data: response,
        didLoaded: true
      };
    case GET_EMAIL_UPDATE_SUCCESS:
      return {
        ...state,
        data: payload.emailUpdate
      };
    default:
      return state;
  }
}
