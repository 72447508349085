import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";
import { getToken } from "./XsrfToken";
import { reset, startSubmit } from "redux-form";
import React from "react";
import { showMessageModal } from "./Modal";

export const FETCH_PROMOTION = "FETCH_PROMOTION";
export const FETCH_PROMOTION_SUCCESS = "FETCH_PROMOTION_SUCCESS";
export const FETCH_PROMOTION_REQUEST = "FETCH_PROMOTION_REQUEST";
export const FETCH_PROMOTION_FAILURE = "FETCH_PROMOTION_FAILURE";

export const FETCH_PROMOCODE = "FETCH_PROMOCODE";
export const FETCH_PROMOCODE_SUCCESS = "FETCH_PROMOCODE_SUCCESS";
export const FETCH_PROMOCODE_REQUEST = "FETCH_PROMOCODE_REQUEST";
export const FETCH_PROMOCODE_FAILURE = "FETCH_PROMOCODE_FAILURE";

const initialState = {
  data: {},
  didLoaded: false,
  errorMessage: "",
  successMessage: ""
};

export const loadingPromotionSelector = createLoadingSelector([
  FETCH_PROMOTION
]);
export const fetchPromotion = () => dispatch => {
  return dispatch(getToken()).then(() => {
    dispatch({
      types: [
        FETCH_PROMOTION_REQUEST,
        FETCH_PROMOTION_SUCCESS,
        FETCH_PROMOTION_FAILURE
      ],
      callAPI: config => apiClient.get(`Cms/GetPromotions`, config),
      payload: {}
    });
  });
};

const submittingRedeemPromocodeSelector = createLoadingSelector([
  FETCH_PROMOCODE
]);
export const redeemPromocode = (data, promoCodeFormId) => (
  dispatch,
  getState
) => {
  const isFetching = submittingRedeemPromocodeSelector(getState());
  if (!data || isFetching) return Promise.resolve();
  return Promise.resolve(dispatch(startSubmit(promoCodeFormId)))
    .then(() => dispatch(getToken()))
    .then(() =>
      dispatch({
        types: [
          FETCH_PROMOCODE_REQUEST,
          FETCH_PROMOCODE_SUCCESS,
          FETCH_PROMOCODE_FAILURE
        ],
        callAPI: config =>
          apiClient.put("Voucher/RedeemPromoCode", data, config),
        payload: { data, formId: promoCodeFormId }
      })
    )
    .then(() => {
      dispatch(reset(promoCodeFormId));
      let successMessage = (
        <div>
          <h3 className="mb-2">Success!</h3>
          <span>Please go to “My Wallet” to view your voucher.</span>
        </div>
      );
      return dispatch(showMessageModal(successMessage));
    })
    .catch(err => {
      const status = err.error.response.status;
      console.log("ERORR", err);
      dispatch(reset(promoCodeFormId));
      let errorMessage = (
        <div>
          <h3 className="mb-2">Unsuccessful!</h3>
          <span>{err.error.response.data.error.message}</span>
        </div>
      );
      return dispatch(showMessageModal(errorMessage));
    });
};

export default function reducer(state = initialState, action) {
  const { errorMessage, response, successMessage } = action;
  switch (action.type) {
    case FETCH_PROMOTION_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case FETCH_PROMOTION_SUCCESS:
      return {
        ...state,
        data: response.data.result,
        didLoaded: true
      };
    case FETCH_PROMOTION_FAILURE:
      return {
        ...state,
        errorMessage
      };

    case FETCH_PROMOCODE_REQUEST:
      return {
        ...state,
        successMessage: "",
        errorMessage: ""
      };
    case FETCH_PROMOCODE_SUCCESS:
      return {
        ...state,
        successMessage
      };
    case FETCH_PROMOCODE_FAILURE:
      return {
        ...state,
        errorMessage
      };
    default:
      return state;
  }
}
