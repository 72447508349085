import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";

export const FETCH_ALL_REGIONS = "FETCH_ALL_REGIONS";
export const FETCH_ALL_REGIONS_REQUEST = "FETCH_ALL_REGIONS_REQUEST";
export const FETCH_ALL_REGIONS_SUCCESS = "FETCH_ALL_REGIONS_SUCCESS";
export const FETCH_ALL_REGIONS_FAILURE = "FETCH_ALL_REGIONS_FAILURE";

export const FETCH_ALL_BRANDS = "FETCH_ALL_BRANDS";
export const FETCH_ALL_BRANDS_REQUEST = "FETCH_ALL_BRANDS_REQUEST";
export const FETCH_ALL_BRANDS_SUCCESS = "FETCH_ALL_BRANDS_SUCCESS";
export const FETCH_ALL_BRANDS_FAILURE = "FETCH_ALL_BRANDS_FAILURE";

export const FETCH_ALL_MALLS = "FETCH_ALL_MALLS";
export const FETCH_ALL_MALLS_REQUEST = "FETCH_ALL_MALLS_REQUEST";
export const FETCH_ALL_MALLS_SUCCESS = "FETCH_ALL_MALLS_SUCCESS";
export const FETCH_ALL_MALLS_FAILURE = "FETCH_ALL_MALLS_FAILURE";

export const FETCH_STORES = "FETCH_STORES";
export const FETCH_STORES_REQUEST = "FETCH_STORES_REQUEST";
export const FETCH_STORES_SUCCESS = "FETCH_STORES_SUCCESS";
export const FETCH_STORES_FAILURE = "FETCH_STORES_FAILURE";

export const FETCH_FEEDBACK_TYPE = "FETCH_FEEDBACK_TYPE";
export const FETCH_FEEDBACK_TYPE_REQUEST = "FETCH_FEEDBACK_TYPE_REQUEST";
export const FETCH_FEEDBACK_TYPE_SUCCESS = "FETCH_FEEDBACK_TYPE_SUCCESS";
export const FETCH_FEEDBACK_TYPE_FAILURE = "FETCH_FEEDBACK_TYPE_FAILURE";

export const FETCH_ALL_CATALOGUE_MENU_TYPES = "FETCH_ALL_CATALOGUE_MENU_TYPES";
export const FETCH_ALL_CATALOGUE_MENU_TYPES_REQUEST =
  "FETCH_ALL_CATALOGUE_MENU_TYPES_REQUEST";
export const FETCH_ALL_CATALOGUE_MENU_TYPES_SUCCESS =
  "FETCH_ALL_CATALOGUE_MENU_TYPES_SUCCESS";
export const FETCH_ALL_CATALOGUE_MENU_TYPES_FAILURE =
  "FETCH_ALL_CATALOGUE_MENU_TYPES_FAILURE";

const initialState = {
  data: {},
  didLoaded: false,
  didLoadedRegions: false,
  didLoadedBrands: false,
  didLoadedMalls: false,
  didLoadedMenu: false,
  didLoadedFbType: false,
  errorMessage: ""
};
export const loadingRegionsSelector = createLoadingSelector([
  FETCH_ALL_REGIONS
]);
export const fetchAllRegions = () => dispatch => {
  return dispatch({
    types: [
      FETCH_ALL_REGIONS_REQUEST,
      FETCH_ALL_REGIONS_SUCCESS,
      FETCH_ALL_REGIONS_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingRegionsSelector(state) && !state.stores.didLoadedRegions,
    callAPI: config => apiClient.get("Store/GetAllRegions", config),
    payload: {}
  });
};

export const loadingBrandsSelector = createLoadingSelector([FETCH_ALL_BRANDS]);
export const fetchAllBrands = () => dispatch => {
  return dispatch({
    types: [
      FETCH_ALL_BRANDS_REQUEST,
      FETCH_ALL_BRANDS_SUCCESS,
      FETCH_ALL_BRANDS_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingRegionsSelector(state) && !state.stores.didLoadedBrands,
    callAPI: config => apiClient.get("Store/GetAllBrands", config),
    payload: {}
  });
};

export const loadingMallsSelector = createLoadingSelector([FETCH_ALL_MALLS]);
export const fetchAllMalls = () => dispatch => {
  return dispatch({
    types: [
      FETCH_ALL_MALLS_REQUEST,
      FETCH_ALL_MALLS_SUCCESS,
      FETCH_ALL_MALLS_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingRegionsSelector(state) && !state.stores.didLoadedMalls,
    callAPI: config => apiClient.get("Store/GetAllMalls", config),
    payload: {}
  });
};

export const fetchStore = () => dispatch => {
  return dispatch({
    types: [FETCH_STORES_REQUEST, FETCH_STORES_SUCCESS, FETCH_STORES_FAILURE],
    callAPI: config => apiClient.get(`Store/GetStores`, config),
    payload: {}
  });
};

export const fetchFeedbackType = () => dispatch => {
  return dispatch({
    types: [
      FETCH_FEEDBACK_TYPE_REQUEST,
      FETCH_FEEDBACK_TYPE_SUCCESS,
      FETCH_FEEDBACK_TYPE_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingRegionsSelector(state) && !state.stores.didLoadedFbType,
    callAPI: config => apiClient.get(`Store/GetFeedbackTypes`, config),
    payload: {}
  });
};

export const loadingCatalogueMenuSelector = createLoadingSelector([
  FETCH_ALL_CATALOGUE_MENU_TYPES
]);
export const fetchAllCatalogueMenuTypes = () => dispatch => {
  return dispatch({
    types: [
      FETCH_ALL_CATALOGUE_MENU_TYPES_REQUEST,
      FETCH_ALL_CATALOGUE_MENU_TYPES_SUCCESS,
      FETCH_ALL_CATALOGUE_MENU_TYPES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingRegionsSelector(state) && !state.stores.didLoadedMenu,
    callAPI: config => apiClient.get("Store/GetAllCatalogueMenuTypes", config),
    payload: {}
  });
};

export default function reducer(state = initialState, action) {
  const { errorMessage, response } = action;
  switch (action.type) {
    case FETCH_ALL_REGIONS_REQUEST:
      return {
        ...state,
        didLoaded: false,
        errorMessage: "",
        successMessage: ""
      };
    case FETCH_ALL_REGIONS_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case FETCH_ALL_REGIONS_SUCCESS:
      return {
        ...state,
        regions: response.data.result,
        didLoadedRegions: true
      };
    case FETCH_ALL_BRANDS_SUCCESS:
      return {
        ...state,
        brands: response.data.result,
        didLoadedBrands: true
      };
    case FETCH_ALL_MALLS_SUCCESS:
      return {
        ...state,
        malls: response.data.result,
        didLoadedMalls: true
      };
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        stores: response.data.result,
        didLoaded: true
      };
    case FETCH_FEEDBACK_TYPE_SUCCESS:
      return {
        ...state,
        feedbackType: response.data.result,
        didLoadedFbType: true
      };
    case FETCH_ALL_CATALOGUE_MENU_TYPES_SUCCESS:
      return {
        ...state,
        catalogueMenu: response.data.result,
        didLoadedMenu: true
      };
    default:
      return state;
  }
}
