/* eslint-disable */
const LangMyAccount = {
	"myAccount._title": ["MY ACCOUNT", "TÀI KHOẢN CỦA TÔI"],
	"myAccount.copy._totalStarEarner": ["Total Stars Earned", "Tổng điểm ngôi sao"],

	"myAccount.copy._xLevel": ["${tierCode}", "${tierCode}"],
	"myAccount.copy._xLevel_Gold": ["Gold", "Vàng"],
	"myAccount.copy._xLevel_Green": ["Green", "Xanh"],
	"myAccount.copy._xLevel_tier": [" Tier", "Hạng thẻ "],
	"myAccount.copy._anniversaryDate": [
		"Anniversary Date <br /> ${date}",
		"Ngày kỷ niệm <br /> ${date}",
	],
	"myAccount.copy._xStarsUntilReward": [
		"You are ${stars} Stars away from <span class='color-secondary d-inline-block'>Gold Tier</span>",
		"Còn ${stars} điểm ngôi sao nữa để <span class='color-secondary d-inline-block'>hạng thẻ Vàng</span>",
	],
	"myAccount.copy._xStarsUntilFreeDrink": [
		"You are ${stars} Stars away from free drink.",
		"Còn ${stars} điểm ngôi sao nữa để nhận ly nước miễn phí.",
	],
	"myAccount.copy._xStarsToRetain": [
		"You are ${stars} Stars away to retain Gold Tier",
		"Còn ${stars} điểm ngôi sao nữa để gia hạng thẻ Vàng",
	],
	"myAccount.copy._extendXLevel": [
		"You are qualified to extend your <span class='color-secondary d-inline-block'>Gold Level</span> for 1 year.",
		"Bạn đã đủ điều kiện được duy trì <span class='color-secondary d-inline-block'>hạng thẻ Vàng </span> cho 1 năm.",
	],
	"myAccount.copy._asOfXDate": ["AS OF ${date}", "ngày cập nhật gần nhất ${date}"],
}

export default LangMyAccount
