/*eslint-disable*/
const LangTransferBalance = {
	"transferBalance.validate._noStoreValue": [
		"This card has no store value balance.",
		"Thẻ này không còn số dư.",
	],
	"transferBalance.header._transferFrom": ["TRANSFER FROM", "CHUYỂN TỪ"],
	"transferBalance.header._transferTo": ["TRANSFER TO", "CHUYỂN ĐẾN"],
	"transferBalance.rules._maxAllow": [
		"A maximum of ${maxAllow} can be stored in one card at any one time",
		"Tối đa số dư được phép trên mỗi thẻ là ${maxAllow}",
	],
}

export default LangTransferBalance
