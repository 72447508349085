const LangContactUs = {
  "contact._title": ["QUESTION OR COMMENTS?", "LIÊN HỆ CHÚNG TÔI"],

  "contact.subTitle._howCanWeHelp": [
    "Need some help on your Abbott Rewards™ account?",
    "Bạn cần trợ giúp cho tài khoản Abbott Rewards™?"
  ],

  "contact.form._description": [
    "<p>We welcome all questions, comments and feedback - it helps us make your experience at Abbott the best it can be.</p><p>Please select the topic below that best fits your comment or question, then e-mail us.</p>",
    "<p>Chúng tôi rất mong nhận được góp ý hcủa bạn để giúp chúng tôi mang đến trải nghiệm tốt nhất cho bạn.</p><p>Hãy chọn chủ đề hoặc câu bạn bạn muốn gửi đến chúng tôi.</p>"
  ],

  "contact.form.label._privacyAgreement": [
    "<p style='font-size: 0.8rem; margin-left: 10px'>I am aware that personal data submitted will be used in accordance with the privacy statement. </p>",
    "Đồng ý rằng các thông tin cá nhân của tôi sẽ được Abbott sử dụng vá tuân thủ các chính sách bảo mật của công ty."
  ],
  "contact.form.name._privacyAgreement": [
    "Privacy Statement",
    "Chính sách bảo mật thông tin"
  ],
  "contact.form._privacyAgreementRules": ["", ""]
};

export default LangContactUs;
