import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";

export const FETCH_SYSTEM_CODES = "FETCH_SYSTEM_CODES";
export const FETCH_SYSTEM_CODES_REQUEST = "FETCH_SYSTEM_CODES_REQUEST";
export const FETCH_SYSTEM_CODES_FAILURE = "FETCH_SYSTEM_CODES_FAILURE";

export const FETCH_MARITAL_STATUS = "FETCH_MARITAL_STATUS";
export const FETCH_MARITAL_STATUS_REQUEST = "FETCH_MARITAL_STATUS_REQUEST";
export const FETCH_MARITAL_STATUS_SUCCESS = "FETCH_MARITAL_STATUS_SUCCESS";
export const FETCH_MARITAL_STATUS_FAILURE = "FETCH_MARITAL_STATUS_FAILURE";

export const FETCH_FAVOURITE_BRANDS = "FETCH_FAVOURITE_BRANDS";
export const FETCH_FAVOURITE_BRANDS_REQUEST = "FETCH_FAVOURITE_BRANDS_REQUEST";
export const FETCH_FAVOURITE_BRANDS_SUCCESS = "FETCH_FAVOURITE_BRANDS_SUCCESS";
export const FETCH_FAVOURITE_BRANDS_FAILURE = "FETCH_FAVOURITE_BRANDS_FAILURE";

export const FETCH_DINE_OUT = "FETCH_DINE_OUT_FREQ";
export const FETCH_DINE_OUT_REQUEST = "FETCH_DINE_OUT_REQUEST";
export const FETCH_DINE_OUT_SUCCESS = "FETCH_DINE_OUT_SUCCESS";
export const FETCH_DINE_OUT_FAILURE = "FETCH_DINE_OUT_FAILURE";

export const FETCH_JAPANES_CUISINE = "FETCH_JAPANES_CUISINE";
export const FETCH_JAPANES_CUISINE_REQUEST = "FETCH_JAPANES_CUISINE_REQUEST";
export const FETCH_JAPANES_CUISINE_SUCCESS = "FETCH_JAPANES_CUISINE_SUCCESS";
export const FETCH_JAPANES_CUISINE_FAILURE = "FETCH_JAPANES_CUISINE_FAILURE";

export const FETCH_COUNTRY_CODES = "FETCH_COUNTRY_CODES";
export const FETCH_COUNTRY_CODES_REQUEST = "FETCH_COUNTRY_CODES_REQUEST";
export const FETCH_COUNTRY_CODES_SUCCESS = "FETCH_COUNTRY_CODES_SUCCESS";
export const FETCH_COUNTRY_CODES_FAILURE = "FETCH_COUNTRY_CODES_FAILURE";

export const FETCH_GENDER_CODES = "FETCH_GENDER_CODES";
export const FETCH_GENDER_CODES_REQUEST = "FETCH_GENDER_CODES_REQUEST";
export const FETCH_GENDER_CODES_SUCCESS = "FETCH_GENDER_CODES_SUCCESS";
export const FETCH_GENDER_CODES_FAILURE = "FETCH_GENDER_CODES_FAILURE";

export const FETCH_NATIONALITY_CODES = "FETCH_NATIONALITY_CODES";
export const FETCH_NATIONALITY_CODES_REQUEST =
  "FETCH_NATIONALITY_CODES_REQUEST";
export const FETCH_NATIONALITY_CODES_SUCCESS =
  "FETCH_NATIONALITY_CODES_SUCCESS";
export const FETCH_NATIONALITY_CODES_FAILURE =
  "FETCH_NATIONALITY_CODES_FAILURE";

const initialState = {
  data: {},
  didLoaded: false,
  didLoadedMarital: false,
  didLoadedFavourite: false,
  didLoadedDineOut: false,
  didLoadedCuisine: false,
  didLoadedCountryCodes: false,
  didLoadedGenderCodes: false,
  didLoadedNationalityCodes: false,
  errorMessage: ""
};

export const loadingMaritalSelector = createLoadingSelector([
  FETCH_MARITAL_STATUS
]);
export const fetchMaritalStatus = () => dispatch => {
  return dispatch({
    types: [
      FETCH_SYSTEM_CODES_REQUEST,
      FETCH_MARITAL_STATUS_SUCCESS,
      FETCH_SYSTEM_CODES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingMaritalSelector(state) && !state.systemCodes.didLoadedMarital,
    callAPI: config =>
      apiClient.get(`SystemCodes/GetMaritalStatusValues`, config),
    payload: {}
  });
};

export const loadingFavouriteSelector = createLoadingSelector([
  FETCH_FAVOURITE_BRANDS
]);
export const fetchFavouriteBrands = () => dispatch => {
  return dispatch({
    types: [
      FETCH_SYSTEM_CODES_REQUEST,
      FETCH_FAVOURITE_BRANDS_SUCCESS,
      FETCH_SYSTEM_CODES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingFavouriteSelector(state) && !state.systemCodes.didLoadedFavourite,
    callAPI: config =>
      apiClient.get(`SystemCodes/GetFavouriteBrandsValues`, config),
    payload: {}
  });
};

export const loadingDineOutSelector = createLoadingSelector([FETCH_DINE_OUT]);
export const fetchDineOutFreq = () => dispatch => {
  return dispatch({
    types: [
      FETCH_SYSTEM_CODES_REQUEST,
      FETCH_DINE_OUT_SUCCESS,
      FETCH_SYSTEM_CODES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingDineOutSelector(state) && !state.systemCodes.didLoadedDineOut,
    callAPI: config =>
      apiClient.get(`SystemCodes/GetDineOutFreqValues`, config),
    payload: {}
  });
};

export const loadingCuisineSelector = createLoadingSelector([
  FETCH_JAPANES_CUISINE
]);
export const fetchJapanesCuisineFreq = () => dispatch => {
  return dispatch({
    types: [
      FETCH_SYSTEM_CODES_REQUEST,
      FETCH_JAPANES_CUISINE_SUCCESS,
      FETCH_SYSTEM_CODES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingCuisineSelector(state) && !state.systemCodes.didLoadedCuisine,
    callAPI: config =>
      apiClient.get(`SystemCodes/GetJapanesCuisineFreqValues`, config),
    payload: {}
  });
};

export const loadingCountryCodesSelector = createLoadingSelector([
  FETCH_COUNTRY_CODES
]);
export const fetchCountryCodes = () => dispatch => {
  return dispatch({
    types: [
      FETCH_COUNTRY_CODES_REQUEST,
      FETCH_COUNTRY_CODES_SUCCESS,
      FETCH_COUNTRY_CODES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingCountryCodesSelector(state) &&
      !state.systemCodes.didLoadedCountryCodes,
    callAPI: config =>
      apiClient.get(`SystemCodes/GetSystemCodes/MobileCountryCodes`, config),
    payload: {}
  });
};

export const loadingGenderCodesSelector = createLoadingSelector([
  FETCH_GENDER_CODES
]);
export const fetchGenderCodes = () => dispatch => {
  return dispatch({
    types: [
      FETCH_GENDER_CODES_REQUEST,
      FETCH_GENDER_CODES_SUCCESS,
      FETCH_GENDER_CODES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingGenderCodesSelector(state) &&
      !state.systemCodes.didLoadedGenderCodes,
    callAPI: config =>
      apiClient.get(`SystemCodes/GetSystemCodes/Gender`, config),
    payload: {}
  });
};

export const loadingNationalityCodesSelector = createLoadingSelector([
  FETCH_NATIONALITY_CODES
]);
export const fetchNationalityCodes = () => dispatch => {
  return dispatch({
    types: [
      FETCH_NATIONALITY_CODES_REQUEST,
      FETCH_NATIONALITY_CODES_SUCCESS,
      FETCH_NATIONALITY_CODES_FAILURE
    ],
    shouldCallAPI: state =>
      !loadingNationalityCodesSelector(state) &&
      !state.systemCodes.didLoadedNationalityCodes,
    callAPI: config =>
      apiClient.get(`SystemCodes/GetSystemCodes/Nationality`, config),
    payload: {}
  });
};

export default function reducer(state = initialState, action) {
  const { errorMessage, response } = action;
  switch (action.type) {
    case FETCH_SYSTEM_CODES_REQUEST:
      return {
        ...state,
        didLoaded: false,
        errorMessage: "",
        successMessage: ""
      };
    case FETCH_SYSTEM_CODES_FAILURE:
      return {
        ...state,
        errorMessage
      };
    case FETCH_MARITAL_STATUS_SUCCESS:
      return {
        ...state,
        marital: response.data.result,
        didLoadedMarital: true
      };
    case FETCH_FAVOURITE_BRANDS_SUCCESS:
      return {
        ...state,
        favouriteBrands: response.data.result,
        didLoadedFavourite: true
      };
    case FETCH_DINE_OUT_SUCCESS:
      return {
        ...state,
        dineOut: response.data.result,
        didLoadedDineOut: true
      };
    case FETCH_JAPANES_CUISINE_SUCCESS:
      return {
        ...state,
        japanesCuisine: response.data.result,
        didLoadedCuisine: true
      };
    case FETCH_COUNTRY_CODES_SUCCESS:
      return {
        ...state,
        countryCodes: response.data.result,
        didLoadedCountryCodes: true
      };
    case FETCH_GENDER_CODES_SUCCESS:
      return {
        ...state,
        genderCodes: response.data.result,
        didLoadedGenderCodes: true
      };
    case FETCH_NATIONALITY_CODES_SUCCESS:
      return {
        ...state,
        nationalityCodes: response.data.result,
        didLoadedNationalityCodes: true
      };
    default:
      return state;
  }
}
