const LangMyTransactions = {
  "myTransactions._title": ["MY TRANSACTIONS", "GIAO DỊCH CỦA TÔI"],
  "myTransactions.header._transactions": ["Transaction", "GIAO DỊCH"],
  "myTransactions.header._cardno": ["Card", "THẺ"],
  "myTransactions.header._noStampEarn": ["Stamps Earned", "Tem"],
  "myTransactions.header._description": ["DESCRIPTION", "DESCRIPTION"],
  "myTransactions.header._amount": ["Amount", "SỐ TIỀN"],
  "myTransactions.header._balance": ["BALANCE", "SỐ TIỀN"],
  "myTransactions.header._starEarned": ["STAR EARNED", "ĐIỂM NGÔI SAO"],
  "myTransactions.header._datetime": ["DATE TIME", "THỜI GIAN"],
  "myTransactions.header._date": ["Date", "THỜI GIAN"],
  "myTransactions.header._time": ["TIME", "THỜI GIAN"],
  "myTransactions.header._outlet": ["Outlet", "CỬA HÀNG"],
  "myTransactions.body._noAvailableRecord": [
    "No transactions found",
    "Tài khoản của bạn hiện chưa có giao dịch"
  ],
  "myTransactions.type.SALES": ["In Store Purchase", "In Store Purchase"],
  "myTransactions.type.PLUS ADJUSTMENT": ["In Store Reload", "In Store Reload"],
  "myTransactions.type.CUSTOMER CARE": [
    "Customer Care Center",
    "Customer Care Center"
  ],
  "myTransactions.type.FUNDS TRANSFER": ["Funds Transfer", "Funds Transfer"],
  "myTransactions.type.MINUS ADJUSTMENT": [
    "MINUS ADJUSTMENT",
    "vi_MINUS ADJUSTMENT"
  ],
  "myTransactions.type.POINTS REDEMPTION": [
    "POINTS REDEMPTION",
    "vi_POINTS REDEMPTION"
  ],
  "myTransactions.type.REFUND SALES ITEMS": [
    "REFUND SALES ITEMS",
    "vi_REFUND SALES ITEMS"
  ],
  "myTransactions.type.POINTS REWARD FOR ITEM": [
    "POINTS REWARD FOR ITEM",
    "vi_POINTS REWARD FOR ITEM"
  ],
  "myTransactions.type.ITEM REDEMPTION": [
    "ITEM REDEMPTION",
    "vi_ITEM REDEMPTION"
  ],
  "myTransactions.type.VOUCHER REDEMPTION": [
    "Reward Redemption",
    "Reward Redemption"
  ],
  "myTransactions._details": ["Details", "Chi tiết"]
};

export default LangMyTransactions;
