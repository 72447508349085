import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";

export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const FETCH_NOTIFICATION_REQUEST = "FETCH_NOTIFICATION_REQUEST";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";

const initialState = {
  data: {},
  didLoaded: false,
  errorMessage: "",
  successMessage: ""
};

export const loadingFetchNotifications = createLoadingSelector([
  FETCH_NOTIFICATION
]);
export const fetchNotifications = data => (dispatch, getState) => {
  const isFetching = loadingFetchNotifications(getState());
  if (!data || isFetching) return Promise.resolve();
  let param = `DeviceUID=${data.DeviceUID}&AppName=${data.AppName}`;
  if (data.BlastID) {
    param += `&BlastID=${data.BlastID}`;
  }
  if (data.BlastHeaderID) {
    param += `&BlastHeaderID=${data.BlastHeaderID}`;
  }
  if (data.FilterFrom) {
    param += `&FilterFrom=${data.FilterFrom}`;
  }
  if (data.FilterTo) {
    param += `&FilterTo=${data.FilterTo}`;
  }
  if (data.SkipCount) {
    param += `&SkipCount=${data.SkipCount}`;
  }
  if (data.MaxResultCount) {
    param += `&MaxResultCount=${data.MaxResultCount}`;
  }
  return dispatch({
    types: [
      FETCH_NOTIFICATION_REQUEST,
      FETCH_NOTIFICATION_SUCCESS,
      FETCH_NOTIFICATION_FAILURE
    ],
    callAPI: config =>
      apiClient.post(
        `MemberAccount/RetrieveInAppMessage?${param}`,
        // data,
        config
      ),
    payload: {}
  });
};

export const fetchNotificationsByID = data => (dispatch, getState) => {

  let param = `DeviceUID=${data.DeviceUID}&AppName=${data.AppName}`;
  if (data.BlastID) {
    param += `&BlastID=${data.BlastID}`;
  }

  return dispatch({
    types: [
      FETCH_NOTIFICATION_REQUEST,
      FETCH_NOTIFICATION_SUCCESS,
      FETCH_NOTIFICATION_FAILURE
    ],
    callAPI: config =>
      apiClient.post(
        `MemberAccount/UpdateRetrieveInAppMessageStatus?${param}`,
        // data,
        config
      ),
    payload: {}
  });
};

export default function reducer(state = initialState, action) {
  const { response } = action;
  switch (action.type) {
    case FETCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: response.data.result
      };
    case FETCH_NOTIFICATION_FAILURE:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    default:
      return state;
  }
}
