import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";
import { getToken } from "./XsrfToken";

export const FETCH_CONTENT = "FETCH_CONTENT";
export const FETCH_ABOUT_REQUEST = "FETCH_ABOUT_REQUEST";
export const FETCH_ABOUT_SUCCESS = "FETCH_ABOUT_SUCCESS";
export const FETCH_ABOUT_FAILURE = "FETCH_ABOUT_FAILURE";

const initialState = {
  about: {},
  didLoaded: false,
  errorMessage: ""
};

export const loadingContent = createLoadingSelector([FETCH_CONTENT]);
export const fetchAbout = () => dispatch => {
  return dispatch(getToken()).then(() => {
    dispatch({
      types: [FETCH_ABOUT_REQUEST, FETCH_ABOUT_SUCCESS, FETCH_ABOUT_FAILURE],
      callAPI: config =>
        apiClient.get(
          `Cms/GetContent?path=MemberPortal/about-us-cate/about-us`,
          config
        ),
      payload: {}
    });
  });
};

export default function reducer(state = initialState, action) {
  const { errorMessage, response } = action;
  switch (action.type) {
    case FETCH_ABOUT_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case FETCH_ABOUT_SUCCESS:
      return {
        ...state,
        about: response.data.result,
        didLoaded: true
      };
    case FETCH_ABOUT_FAILURE:
      return {
        ...state,
        errorMessage
      };
    default:
      return state;
  }
}
