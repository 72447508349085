import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import classNames from "classnames";
import { NavHashLink as NavLink } from "react-router-hash-link";
import Logout from "../../images/icons/logout2.png";
import IconNotiNew from "../../images/icons/icon-noti-new.png";
import Logo from "../../images/RE_Logo.png";
import Logo2 from "../../images/logo.png";
import IconNoti from "../../images/icons/icon-noti.png";
import { connect } from "react-redux";
import { isEmptyObj } from "../../helpers";
import {
  fetchProfile,
  loadingProfileSelector
} from "../../redux/modules/Profile";
import Link from "react-router-dom/Link";
import { logout } from "../../redux/modules/Auth";
import { showConfirmMessage } from "../../redux/modules/Modal";
import { bindActionCreators } from "redux";
import { fetchNotifications } from "../../redux/modules/Notification";
import moment from "moment";
import { sendInAppMessage } from "../../redux/modules/Hub";

const NUMBER = 0;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDot: false,
      isAnimate: false
    };
  }
  componentDidMount() {
    let { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.props.fetchProfile().then(() => {
        let fliterTo = moment().format();
        let filterFrom = moment()
          .subtract(6, "months")
          .format();
        let memberId = this.props.profile.data.memberID;
        let data = {
          DeviceUID: memberId,
          AppName: "RES",
          BlastID: "",
          BlastHeaderID: "",
          FilterFrom: filterFrom,
          FilterTo: fliterTo
          // SkipCount: 0,
          // MaxResultCount: 1000
        };
        this.props.fetchNotifications(data).then(() => {
          if (this.props.notification.notification) {
            let showDot = false;
            let notifications = this.props.notification.notification
              .inAppInfoLists;
            let len = notifications.length;
            let unRead = 0;
            for (let i = 0; i < len; i++) {
              if (!notifications[i].readStatus) {
                // showDot = true;
                unRead++;
                // break;
              }
            }
            if (unRead > NUMBER) {
              showDot = true;
            }
            this.setState({
              showDot: showDot
            });
          }
        });
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.hubs.dataMes) {
      if (
        nextProps.notification !== this.props.notification &&
        nextProps.notification.notification
      ) {
        let showDot = false;
        let isAnimate = false;
        let notifications = nextProps.notification.notification.inAppInfoLists;
        let len = notifications.length;
        let unRead = 0;
        for (let i = 0; i < len; i++) {
          if (!notifications[i].readStatus) {
            // showDot = true;
            // isAnimate = true;
            unRead++;
            // break;
          }
        }
        if (unRead > NUMBER) {
          showDot = true;
          isAnimate = true;
        }
        this.setState({
          showDot: showDot,
          isAnimate: isAnimate
        });
      }
    } else {
      if (
        nextProps.notification !== this.props.notification &&
        nextProps.notification.notification
      ) {
        let showDot = false;
        let notifications = nextProps.notification.notification.inAppInfoLists;
        let len = notifications.length;
        let unRead = 0;
        for (let i = 0; i < len; i++) {
          if (!notifications[i].readStatus) {
            // showDot = true;
            unRead++;
            // break;
          }
        }
        if (unRead > NUMBER) {
          showDot = true;
        }
        this.setState({
          showDot: showDot,
          isAnimate: false
        });
      } else {
        this.setState({
          isAnimate: false
        });
      }
    }
  }
  renderMenu = (
    { path, title, exact, className },
    locale,
    handleCloseMenu,
    index
  ) => (
    <li key={index}>
      <NavLink
        activeClassName="active"
        exact={exact}
        onClick={handleCloseMenu}
        to={`/${locale}${path}`}
        className={className}
      >
        {<Translate id={title} />}
      </NavLink>
    </li>
  );
  onClickLogOut = () => {
    this.props.showConfirmMessage(
      "Are you sure you want to log out?",
      () => this.props.logout(),
      null,
      "Logout",
      "Cancel"
    );
  };

  render() {
    const {
      isAuthenticated,
      isMenuOpen,
      handleToggleMenu,
      handleCloseMenu,
      initialValues
    } = this.props;
    let name = initialValues.firstName;
    let nowUrl = this.props.location.pathname;
    return (
      <Translate>
        {(translate, activeLanguage, languages) => {
          return (
            <header
              id="header"
              className={classNames("container-fluid", {
                "is-open": isMenuOpen
              })}
            >
              <div className="row align-items-left justify-content-left box-shadow">
                <label
                  onClick={isAuthenticated && handleToggleMenu}
                  // className={classNames("mobile-menu-toggler", {
                  //   unauthorized: !isAuthenticated
                  // })}
                  className="mobile-menu-toggler"
                >
                  <div className="bar1" />
                  <div className="bar2" />
                  <div className="bar3" />
                </label>

                <label
                  onClick={handleCloseMenu}
                  className="mobile-menu-backdrop"
                />
                <div className="mobile-menu-fake-header" />

                <div className="col-6 col-md-3 py-2 logo">
                  <NavLink
                    to={`/${isAuthenticated ? "rewards-card" : "sign-in"}`}
                    onClick={handleCloseMenu}
                  >
                    <img src={isAuthenticated ? Logo : Logo2} alt="RE&S" />
                  </NavLink>
                </div>
                {isAuthenticated ? (
                  <div>
                    <div className="head-logout">
                      <img
                        className="icon-logout"
                        src={Logout}
                        alt=""
                        onClick={this.onClickLogOut}
                      />
                      <div
                        className={classNames("notifi-page", {
                          "notifi-page-htu": nowUrl === "/how-to-use"
                        })}
                      >
                        <Link to="/notifications" className="notification">
                          {!this.state.showDot && (
                            <img
                              className="pl-3 icon-noti"
                              src={IconNoti}
                              alt=""
                            />
                          )}
                          {this.state.showDot && !this.state.isAnimate && (
                            <img
                              className="pl-3 icon-noti"
                              src={IconNotiNew}
                              alt=""
                            />
                          )}
                          {this.state.showDot && this.state.isAnimate && (
                            <img
                              className="pl-3 icon-noti bell"
                              src={IconNotiNew}
                              alt=""
                            />
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="head-welcome">
                      <h5 className="text-link">
                        {`Welcome, ${name ? name : "...."}`}
                        <span>
                          <Link to="/notifications">
                            {!this.state.showDot && (
                              <img
                                className="pl-3 icon-noti"
                                src={IconNoti}
                                alt=""
                              />
                            )}
                            {this.state.showDot && !this.state.isAnimate && (
                              <img
                                className="pl-3 icon-noti"
                                src={IconNotiNew}
                                alt=""
                              />
                            )}
                            {this.state.showDot && this.state.isAnimate && (
                              <img
                                className="pl-3 icon-noti bell"
                                src={IconNotiNew}
                                alt=""
                              />
                            )}
                          </Link>
                        </span>
                      </h5>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="head-logout">
                      {nowUrl === "/" || nowUrl === "/sign-in" ? (
                        <div></div>
                      ) : (
                        <Link to={`/sign-in`}>
                          <img className="icon-logout" src={Logout} alt="" />
                        </Link>
                      )}
                    </div>
                    <div className="head-welcome">
                      <h5 className="text-signup">
                        <Link
                          className="mr-3"
                          to={
                            nowUrl === "/" || nowUrl === "/sign-in"
                              ? "/sign-up"
                              : "/sign-in"
                          }
                        >
                          {nowUrl === "/sign-up" ? "Sign In" : "Sign Up"}
                          <span className="card-header-arrow"></span>
                        </Link>
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </header>
          );
        }}
      </Translate>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  const {
    profile: { data: initialValues, errorMessage },
    loading,
    profile,
    notification,
    hubs
  } = state;

  return {
    initialValues,
    isFetching:
      loadingProfileSelector({ loading }) || isEmptyObj(initialValues),
    errorMessage,
    profile,
    notification,
    hubs
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(fetchProfile()),
  showConfirmMessage: bindActionCreators(showConfirmMessage, dispatch),
  logout: bindActionCreators(logout, dispatch),
  fetchNotifications: bindActionCreators(fetchNotifications, dispatch),
  sendInAppMessage: bindActionCreators(sendInAppMessage, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
