import React, { Component } from "react";
import ReactModal from "react-modal";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postReply } from "../../../redux/modules/Decision";
import {
  showMessageModal,
  showConfirmMessage
} from "../../../redux/modules/Modal";
import {
  receiveNewStamp,
  pendingReplyApi,
  isReload,
  saveEvents
} from "../../../redux/modules/Hub";

class ModalDecisionBase extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    ReactModal.setAppElement("#root");
  }

  handleClick(option) {
    const { hideModal, type } = this.props;
    let data = {
      id: this.props.modalDecision.id,
      reply: option
    };
    this.props
      .postReply(data)
      .then(() => {
        if (this.props.decision.postReply) {
          this.props.showMessageModal(this.props.decision.postReply, () => {
            this.handleModalType(type);
          });
        } else {
          this.handleModalType(type);
        }
        window.location.reload();
      })
      .catch(() => {
        this.handleModalType(type);
      });
    hideModal();
  }

  handleModalType = type => {
    if (type == 1) {
      this.props.saveEvents("");
      this.props.pendingReplyApi(false);
      this.props.isReload(false);
      this.props.receiveNewStamp("");
    } else if (type == 2) {
      this.props.pendingReplyApi(false);
    }
  };

  render() {
    const {
      showModal,
      question,
      message,
      isOpen,
      children,
      ...props
    } = this.props;
    let options = this.props.modalDecision.options;
    let decisionType = this.props.modalDecision.decisionType;
    let title = this.props.modalDecision.title;
    return (
      <Translate>
        {(translate, activeLanguage) => (
          <ReactModal
            isOpen={isOpen}
            onAfterOpen={this.handleAfterOpenModal}
            // onRequestClose={this.handleClick}
            {...props}
          >
            <h3 className="text-center py-2">
              {decisionType === "EXCHANGE VOUCHER" ? "Exchange Voucher" : title}
            </h3>
            <h6 className="modal-body pt-3 text-center">
              {children ? children : ""}
            </h6>
            <div className="modal-footer d-flex justify-content-center">
              {options &&
                options.map(option => {
                  return (
                    // <button
                    //     className="btn btn-primary btn-small btn-modal-ok"
                    //     onClick={this.handleClick.bind(this, option)}
                    // >
                    //     {option}
                    // </button>
                    <button
                      className="btn btn-small btn-modal-cancel ml-2"
                      onClick={this.handleClick.bind(this, option)}
                    >
                      {option}
                    </button>
                  );
                })}
            </div>
          </ReactModal>
        )}
      </Translate>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postReply: bindActionCreators(postReply, dispatch),
  showMessageModal: bindActionCreators(showMessageModal, dispatch),
  showConfirmMessage: bindActionCreators(showConfirmMessage, dispatch),
  pendingReplyApi: bindActionCreators(pendingReplyApi, dispatch),
  receiveNewStamp: bindActionCreators(receiveNewStamp, dispatch),
  isReload: bindActionCreators(isReload, dispatch),
  saveEvents: bindActionCreators(saveEvents, dispatch)
});
const mapStateToProps = state => ({
  decision: state.decision,
  pendingEvent: state.hubs.pendingEvent
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalDecisionBase);
