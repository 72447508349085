import apiClient from "../../helpers/apiClient";
import { createLoadingSelector } from "./Loading";
import { getToken } from "./XsrfToken";
import { reset, startSubmit } from "redux-form";
import React from "react";
import { showMessageModal } from "./Modal";

export const FETCH_OTAC = "FETCH_OTAC";
export const FETCH_OTAC_SUCCESS = "FETCH_OTAC_SUCCESS";
export const FETCH_OTAC_REQUEST = "FETCH_OTAC_REQUEST";
export const FETCH_OTAC_FAILURE = "FETCH_OTAC_FAILURE";

const initialState = {
  data: {},
  didLoaded: false,
  errorMessage: "",
  successMessage: ""
};

export const generatingOtacSelector = createLoadingSelector([FETCH_OTAC]);
export const fetchOtac = (values, generateOtacFormId) => (
  dispatch,
  getState
) => {
  const isFetching = generatingOtacSelector(getState());
  if (!values || isFetching) return Promise.resolve();
  let data = {
    memberId: values
  };
  return Promise.resolve(dispatch(startSubmit(generateOtacFormId)))
    .then(() => dispatch(getToken()))
    .then(() =>
      dispatch({
        types: [FETCH_OTAC_REQUEST, FETCH_OTAC_SUCCESS, FETCH_OTAC_FAILURE],
        callAPI: config => apiClient.post(`Otac/GenerateOTAC`, data, config),
        payload: { values, formId: generateOtacFormId }
      })
    );
};

export default function reducer(state = initialState, action) {
  const { errorMessage, response, successMessage } = action;
  switch (action.type) {
    case FETCH_OTAC_REQUEST:
      return {
        ...state,
        errorMessage: "",
        successMessage: ""
      };
    case FETCH_OTAC_SUCCESS:
      return {
        ...state,
        data: response.data.result,
        didLoaded: true
      };
    case FETCH_OTAC_FAILURE:
      return {
        ...state,
        errorMessage
      };
    default:
      return state;
  }
}
