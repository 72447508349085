import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  getTranslate,
  getActiveLanguage,
  Translate
} from "react-localize-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import {
  SidebarMenuLinks,
  DropdownLinks,
  AboutLinks
} from "../../configs/MenuLinks";
import { closeMobileMenu } from "../../redux/modules/MobileMenu";
import { showConfirmMessage } from "../../redux/modules/Modal";
import Collapse from "../components/common/Collapse";
import LgSignOut from "../../images/RE_Menu_Sign_Out.png";
import classNames from "classnames";

class SideNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }

  handleLogout = () => {
    this.props.showConfirmMessage(
      "Are you sure you want to log out?",
      () => this.redirect("/logout"),
      null,
      "Logout",
      "Cancel"
    );
  };

  renderMenu = ({ path, title, exact, className, items, img }, index) => {
    const url = `${path}`;
    const children = (
      <ul className="side-bar_list">
        {DropdownLinks.map((path, index) =>
          this.renderMenuItems(path, index, undefined, true)
        )}
      </ul>
    );
    const heading = (
      <li className="side-bar_list">
        {AboutLinks.map((path, index) =>
          this.renderMenuItems(path, index, true)
        )}
      </li>
    );
    return (
      <div>
        {items && items.length > 0 ? (
          <Collapse heading={heading} children={children} />
        ) : (
          <li
            key={index}
            className="side-bar_list_item my-2"
            onClick={() => this.redirect(url)}
          >
            <NavLink
              onClick={e => e.preventDefault()}
              exact={exact}
              to={url}
              activeClassName="active"
              className={className}
            >
              <Translate id={title} />
              <img src={img} alt="" className="ic-sidebar" />
            </NavLink>
          </li>
        )}
      </div>
    );
  };

  renderMenuItems = (
    { path, title, exact, className, img },
    index,
    isHeading,
    isChildren
  ) => {
    const url = `${path}`;
    return (
      <li
        key={index}
        className={classNames("side-bar_list_item my-2", {
          "is-children": isChildren
        })}
        onClick={() => this.redirect(url)}
      >
        <NavLink
          onClick={e => e.preventDefault()}
          exact={exact}
          to={url}
          activeClassName="active"
          className={className}
        >
          <span className="menuWithDropdown">
            <Translate id={title} />
          </span>
          {isHeading && <img src={img} alt="" className="ic-sidebar-about" />}
        </NavLink>
      </li>
    );
  };

  redirect = url => {
    const { push, closeMenu, isMenuOpen } = this.props;

    if (isMenuOpen)
      return Promise.resolve()
        .then(() => closeMenu())
        .then(() =>
          setTimeout(() => {
            push(url);
          }, 250)
        );
    return push(url);
  };

  render() {
    return (
      <div className="side-bar">
        <ul className="side-bar_list my-sm-3 my-md-5">
          {SidebarMenuLinks.map((path, index) => this.renderMenu(path, index))}
          <li
            key={"logout"}
            className="side-bar_list_item my-2 log-out"
            onClick={this.handleLogout}
          >
            <NavLink
              onClick={e => e.preventDefault()}
              exact={""}
              to={""}
              activeClassName="active"
              className={"link"}
            >
              Logout
            </NavLink>
            <img src={LgSignOut} alt="" className="ic-sidebar" />
          </li>
        </ul>
      </div>
    );
  }
}

SideNavBar.propTypes = {
  location: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth, locale, mobileMenu }) => ({
  translate: getTranslate(locale),
  currentLanguage: getActiveLanguage(locale).code,
  isMenuOpen: mobileMenu.isMenuOpen
});

const mapDispatchToProps = dispatch => ({
  closeMenu: bindActionCreators(closeMobileMenu, dispatch),
  push: bindActionCreators(push, dispatch),
  showConfirmMessage: bindActionCreators(showConfirmMessage, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNavBar);
